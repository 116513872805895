import styled, { css } from "styled-components";
export interface ISection {
  sectionColor: string;
}
export const Container = styled.section<ISection>`
  ${({ sectionColor }) => css`
    background-color:${sectionColor}};
  `}
  position:relative;

  svg {
    z-index: 20;
    &:first-child {
      margin-top: -35rem;
      position: relative;
    }
    &:not(:first-child) {
      margin-bottom: -1rem;
      margin-top: 13rem;
    }
  }

  h2 {
    color: var(--green-lighter);
    font-weight: 300;
    line-height: 11.6rem;
    margin-bottom: 2.3rem;
  }

  .sub-container {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    @media (min-width: 1300px) {
      flex-wrap: nowrap;
      justify-content: flex-start;

      .first-column {
        width: 20%;
        position: relative;
      }
      .second-column {
        margin-left: 9rem;
      }
    }
  }

  .first-column {
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
  }

  .second-column {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;

    max-width: 67.4rem;

    h4 {
      font-weight: 400;
      color: var(--white);
      margin-bottom: 2.2rem;
    }
    p {
      color: var(--white);
      font-size: 1.8rem;
      line-height: 2.8rem;
    }

    p,
    strong + p,
    strong {
      margin-bottom: 3rem;
    }

    strong {
      color: var(--brown-darker);
      font-size: 2.2rem;
      font-weight: 600;
    }

    @media (max-width: 1300px) {
      h4 {
        display: none;
      }
    }
  }
`;

export const Profile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .profile-image-wrapper {
    position: relative;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    img {
      width: 13.9rem;
      width: 13.9rem;
    }
    a {
      position: absolute;
      bottom: -10%;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--white);
      svg {
        margin: 0;
      }
    }
  }

  h4 {
    position: absolute;
    left: 10.2rem;
    bottom: 0;
    width: 18.3rem;
    font-size: 2.4rem;
    color: var(--white);
    font-weight: normal;
    line-height: 3.3rem;
  }

  @media (min-width: 1300px) {
    .profile-image-wrapper {
      img {
        width: 27.4rem;
        width: 27.4rem;
      }
      a {
        width: 6.4rem;
        height: 6.4rem;
      }
    }
    h4 {
      display: none;
    }
  }
`;

export const GeometricShape = styled.div`
  background-color: var(--brown-lighter);
  width: 6.7rem;
  height: 19.9rem;
  border-radius: 1.1rem;

  @media (min-width: 1300px) {
    width: 14.7rem;
    height: 100%;
  }
`;
