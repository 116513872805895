import styled from "styled-components";

export const FooterWrapper = styled.footer`
  display: flex;
  background-color: var(--green);

  > div {
    padding: 6.5rem 0rem 6.58rem 0rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 20rem;

    @media (min-width: 900px) {
      justify-content: space-between;
      flex-wrap: wrap;
      flex-flow: row;
    }

    .media-links {
      display: flex;
      flex-flow: row;
      align-items: center;
      width: 10.3rem;
      flex-wrap: nowrap;

      @media (max-width:1200px){
        margin:4rem;        
      }
      
      a + a {
        margin-left: 5.6rem;
      }
    }

    .links {
      display: grid;
      grid-gap: 3rem;
      a + a {
        margin-left: 0px;
      }
      @media (min-width: 900px) {
        display: flex;
        flex-flow: row;
        align-items: center;
        flex-wrap: wrap;
      }

      @media (min-width: 1700px) {
        a + a {
          margin-left: 17.1rem;
        }
      }

      a {
        color: var(--white);
        text-decoration: none;
        color: var(--white);
        font-size: 1.6rem;
        line-height: 2.4rem;
        :hover {
          color: var(--green-lighter);
          transition: color 0.2s;
          &:last-child {
            color: var(--brown);
          }
        }
      }
    }
  }
`;

export const LinkText = styled.a``;

export const LinkInIcon = styled(LinkText)`
  + a {
    margin-left: 5.6rem;
  }
  svg {
    color: var(--white);
    font-size: 2.6rem;
    transition: color 0.2s;
    :hover {
      color: var(--green-lighter);
    }
  }
`;
