import styled, { css } from "styled-components";

interface ISelectProps {
  off?: boolean;
}
export const SelectBlock = styled.div<ISelectProps>`
  ${({ off }) =>
    off &&
    css`
      opacity: 0.4;
    `}
  transition:opacity 0.2s linear;
  position: relative;
  background: var(--grey);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.8rem;
  padding: 1.3rem 2.1rem 1rem 2.1rem;

  label {
    font-size: 1.6rem;
  }

  select {
    width: 100%;
    background: var(--grey);
    border: none;
    outline: none;
    font-size: 1.8rem;
    ${({ off }) =>
      off &&
      css`
        color: var(--grey);
      `}
  }
`;
