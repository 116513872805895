import styled from "styled-components";

export const Container = styled.div`
  z-index: 100;
  background-color: var(--green);
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  padding: 4.5rem 3.6rem 7rem 3.6rem;
  display: grid;
  grid-gap: 6.4rem;
  animation: MobileMenuShowsUp 0.6s linear forwards;

  ul.mobile-menu-pages {
    opacity: 0;
    animation: MobileMenuShowsUp 0.8s linear forwards;
    animation-delay: 0.1s;
    width: 100%;
    li {
      position: relative;
      display: flex;
      &:before {
        content: "";
        transition: background-color 0.2s linear forwards;
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0px;
        left: -3.6rem;
        z-index: -1;
      }

      &:hover {
        cursor: pointer;
        &:before {
          background: var(--green-darker);
        }
      }
      a {
        padding: 1.8rem 0rem;
        font-size: 1.8rem;
        color: var(--white);
        text-decoration: none;
      }
    }
  }

  .mobile-menu {
    &-header {
      opacity: 0;
      animation: MobileMenuShowsUp 0.8s linear forwards;
      animation-delay: 0.2s;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        height: 4.467rem;
        color: var(--brown);
      }
      button {
        background: none;
        display: flex;

        svg {
          color: var(--white);
          font-size: 3.4rem;
        }
      }
    }

    &-footer {
      display: grid;
      grid-gap: 5rem;
      justify-content: center;
      opacity: 0;
      animation: MobileMenuShowsUp 0.8s linear forwards;
      animation-delay: 0.3s;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: var(--white);
          &:hover {
            color: var(--green-lighter);
          }
        }
      }
      ul {
        display: grid;
        grid-gap: 2.5rem;
        text-align: center;

        li a {
          color: var(--white);
          text-decoration: none;
          font-size: 1.4rem;
          transition: color 0.2s;

          &:hover {
            color: var(--green-lighter);
          }
          &#PriscilaFelippetoLink {
            &:hover {
              color: var(--brown);
            }
          }
        }
      }
    }
  }

  @keyframes MobileMenuShowsUp {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`;

export const GoToForm = styled.a`
  opacity: 0;
  animation: MobileMenuShowsUp 0.8s linear forwards;
  animation-delay: 0.4s;
  width: 100%;
  max-width: 47.5rem;
  justify-self: center;
  height: fit-content;
  text-decoration: none;

  display: flex;
  background-color: var(--green-lighter);
  color: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 1.1rem;
  width: fit-content;
  padding: 2.1rem 4rem;
  font-size: 1.8rem;

  transition: background-color 0.2s linear;
  &:hover {
    cursor: pointer;
    background-color: var(--brown-darker);
  }

  @media (max-width: 600px) {
    padding: 1.7rem 4rem;
    font-size: 1.6rem;
  }
`;
