import React from "react";
import { SVGCurvedShape } from "./styles";

interface ICurvedProps {
  customColor?: string;
}

const CurvedShape: React.FC<ICurvedProps> = ({ customColor }) => {
  return <SVGCurvedShape color={customColor} />;
};

export default CurvedShape;
