import styled, { css } from "styled-components";

interface IFeedBackSearchProps {
  found: boolean;
}

export const Container = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
    max-width: 117.8rem;
  }
  div + div {
    margin-top: 0.4rem;
  }
`;
export const FeedbackSearch = styled.div<IFeedBackSearchProps>`
  background-color: var(--green-lighter);

  padding: 2.2rem 3.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  grid-gap: 3.4rem;
  width: 100%;
  border-radius: 1.1rem;
  animation: showInfos 0.8s linear;

  ${({ found }) =>
    !found &&
    css`
      background-color: var(--green-darker);
    `}
  p, button {
    color: var(--white);
    font-size: 1.6rem;
  }
  p {
    max-width: 79.1rem;
    margin-bottom: 0rem !important;
  }
  button {
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    min-width: 18.4rem;
    align-self: center;
    font-weight: bold;
    padding: 2.2rem 3.1rem;
    border-radius: 1.1rem;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: var(--green);
      ${({ found }) =>
        !found &&
        css`
          background-color: var(--green-lighter);
        `}
    }
    svg {
      background-color: transparent;
    }
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
`;
