import styled, { css } from "styled-components";
export interface ISection {
  sectionColor: string;
}
export const Container = styled.section<ISection>`
  ${({ sectionColor }) => css`
    background-color:${sectionColor}};
  `}
  position:relative;

  svg {
    z-index: 20;

    &:not(:first-child) {
      margin-bottom: -1rem;
      margin-top: 20rem;
    }
  }

  h2 {
    color: var(--green-lighter);
    font-weight: 300;
    margin-top: 4.3rem;
    line-height: 6rem;
    margin-bottom: 2.3rem;
    @media (min-width: 1200px) {
      margin-bottom: 4rem;
    }
  }

  .sub-container {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    @media (min-width: 1300px) {
      flex-wrap: nowrap;

      .first-column {
        margin-bottom: 0rem;
      }

      .second-column {
        max-width: 60rem;
      }
    }
    
    @media (max-width: 1020px) {
      .first-column {
        margin: 0rem;
        margin-bottom: 4rem;
      }
    }
  }

  .first-column {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    margin: 2rem;
    max-width: 67.4rem;
    margin-bottom: 9rem;

    h4 {
      font-weight: 400;
      color: var(--green-darker);
      margin-bottom: 2.2rem;
    }
    p {
      color: var(--green-darker);
      font-size: 1.8rem;
      line-height: 2.8rem;
      white-space: pre-line;
    }

    p,
    strong + p,
    strong {
      margin-bottom: 4rem;
    }

    strong {
      color: var(--green-darker);
      font-size: 2.2rem;
      font-weight: 400;
    }
  }

  .second-column {
    display: flex;
    width: 100%;
    flex-flow: column;

    div > div p {
      width: 96.36%;
    }
    div > button h4 {
      text-transform: capitalize;
    }

    div + div {
      margin-top: 2rem;
    }
  }
`;

export const CallToAction = styled.div`
  b {
    width: 97%;
    display: flex;
  }
  button {
    margin-top: 3.2rem;
  }
`;
