import React, { InputHTMLAttributes, useRef } from "react";
import { InputBlock } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  off?: boolean;
  customRef?: React.MutableRefObject<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({
  name,
  label,
  off,
  children,
  customRef,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  return (
    <InputBlock off={off}>
      <label htmlFor={name}>{label}</label>
      <input
        required={!off}
        ref={customRef || inputRef}
        type="text"
        name={name}
        id={name}
        {...rest}
      />
      {children}
    </InputBlock>
  );
};
