import styled, { css } from "styled-components";
export interface ISection {
  sectionColor: string;
}
export const Section = styled.section<ISection>`
  ${({ sectionColor }) => css`
    background-color:${sectionColor}};
  `}
  position:relative;

  svg {
    z-index: 20;
    &:not(:first-child) {
      margin-bottom: -1rem;
      margin-top: 13rem;
    }
  }

  h2 {
    color: var(--green-lighter);
    font-weight: 300;
    margin-bottom: 2.3rem;
    margin-top: 4.3rem;
    line-height: 6rem;
    @media (min-width: 1200px) {
      margin-bottom: 4rem;
    }
  }

  .sub-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem 8.4rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .first-column,
  .second-column {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    grid-gap: 2rem;

    h4 {
      font-weight: 400;
      color: var(--white);
      margin-top: 2.2rem;
      
    }
    p {
      color: var(--white);
      font-size: 1.8rem;
      line-height: 2.8rem;
    }

    p,
    strong + p,
    strong {
      margin-bottom: 3rem;
    }

    strong {
      color: var(--brown-darker);
      font-size: 2.2rem;
      font-weight: 400;
    }
  }
`;
