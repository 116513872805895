import { createGlobalStyle } from "styled-components";
import "./colors.css";
import "./animations.css";

const GlobalStyles = createGlobalStyle`
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;

}
:root{
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 

 
    html {
    scroll-behavior: smooth;
    } 
    html, body, #root{
        height: 100vh;
    
    }
    body{
        background-color: var(--purple);
        
    }
    
}
 
   
body,
input,
select,
button,
textarea{
    font: 400 1.6rem 'Open Sans', sans-serif;
    color:var(--green-darker);
}

input, button{
    border: none;
  outline: none;
  cursor:pointer;
}

h1{
   font-size: 13.1rem;
   color:var(--white);
   font-weight:300;
   line-height:15rem;
}
h2{
    font-size: 8.5rem;
}
h3,h4,h5{
    font-size: 3.2rem;
}

ul{
    list-style:none;
}

.container,.sub-container{
    margin-left:auto;
    margin-right:auto;
    width:92.1%;

    
}
.container{
    max-width: 164.1rem;
}
.sub-container{
    max-width: 136.4rem;
}
.hide-me{
    display:none;
}
@media (min-width:700px){
    :root{
        font-size: 62.5%;
    }
}


@media (max-width:1200px){
    h1{
    font-size: 10.2rem;
    line-height:10.1rem;
    }
    h2{
        font-size: 5.5rem;
    }
    h3,h4,h5{
        font-size: 2.4rem;
    }
}

@media(max-width:600px){
    h1{
        font-size:7rem;
        line-height:7.1rem;
    }
}
`;

export default GlobalStyles;
