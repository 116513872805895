import styled from "styled-components";

export const PrivacyWrapper = styled.div`
  height: 100vh;
  background-color: var(--grey);
`;

export const Main = styled.main`
  padding-bottom: 12rem;
  background-color: var(--grey);
  h2 {
    color: var(--green-lighter);
    font-weight: 300;
    margin-top: 8.2rem;
    margin-bottom: 3.5rem;
  }

  div + div {
    margin-top: 2rem;
  }

  @media (min-width: 900px) {
    h2 {
      margin-top: 13.2rem;
      margin-bottom: 5.5rem;
      line-height: 11.6rem;
    }
  }
`;
