import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { familyNames as families } from "../../data/database";
import { Input } from "../Input";
import { Container, FeedbackSearch } from "./styles";

const SearchBar: React.FC = () => {
  const history = useHistory();

  const [family, setFamily] = useState("");
  const searchInputField = useRef<HTMLInputElement>({} as HTMLInputElement);
  const [familyFound, setFamilyFound] = useState<boolean>(false);

  const handleFamilyNames = (names: string[]) => {
    return names.map((name) => name.toUpperCase());
  };

  const familyNames = useMemo(() => handleFamilyNames(families), []);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchResult: boolean = familyNames.some(
        (name) => name === event.target.value.toUpperCase()
      );
      setFamilyFound(searchResult);
    },
    [familyNames]
  );
  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    const { value } = searchInputField.current;
    setFamily(value.toUpperCase());
  }, []);

  return (
    <Container
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <Input
        role="Botão para buscar nome de famílias de clientes já atendidos."
        id="Search"
        label="Seu sobrenome:"
        name="search"
        required={false}
        customRef={searchInputField}
        onChange={handleSearchChange}
      >
        <button type="submit">
          <FiSearch size={28} />
        </button>
      </Input>
      {!!family && (
        <>
          {familyFound && (
            <FeedbackSearch found={familyFound}>
              <p>
                <b>Seu sobrenome foi localizado em nosso acervo!</b> Se desejar
                conversar com nossa equipe sobre o seu processo de cidadania,
                preencha nosso formulário. Assim poderemos analisar suas
                informações.
              </p>
              <button
                onClick={async () => {
                  await history.push("");
                  history.push("/Contato");
                }}
              >
                Preencher formulário
              </button>
            </FeedbackSearch>
          )}
          {!familyFound && (
            <FeedbackSearch found={familyFound}>
              <p>
                <b>Seu sobrenome não foi localizado em nosso acervo.</b> Se
                desejar conversar com nossa equipe sobre o seu processo de
                cidadania, preencha nosso formulário. Assim poderemos analisar
                suas informações.
              </p>
              <button
                onClick={async () => {
                  await history.push("");
                  history.push("/Contato");
                }}
              >
                Preencher formulário
              </button>
            </FeedbackSearch>
          )}
        </>
      )}
    </Container>
  );
};

export default SearchBar;
