import styled from "styled-components";

export const Container = styled.header`
  max-width: 100%;

  &.header-fixed {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: fit-content;
    top: 0px;
    animation: top-down 0.8s linear;
    background-color: var(--grey);
  }
  &.header-fixed-hidden {
    display: none;
  }

  @keyframes top-down {
    from {
      transform: translateY(-20rem);
      opacity: 0.65;
    }
    to {
      transform: translateY(0rem);
      opacity: 1;
    }
  }

  .container {
    padding: 3.2rem 0rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 4rem;
  }

  .links {
    display: none;
  }

  @media (min-width: 900px) {
    .links {
      display: flex;
      flex-flow: row;
      align-items: center;
      flex-wrap: wrap;

      a + a,
      p + p,
      p + a {
        margin-left: 6.9rem;
      }
    }
  }
`;

export const LinkText = styled.a`
  text-decoration: none;
  color: var(--green);
  font-size: 1.8rem;
  line-height: 2.4rem;
  cursor: pointer;

  :hover {
    color: var(--green-lighter);
    transition: color 0.2s;
  }
`;
export const LinkInIcon = styled.a`
  text-decoration: none;
  color: var(--green);
  font-size: 1.8rem;
  line-height: 2.4rem;
  :hover {
    color: var(--green-lighter);
    transition: color 0.2s;
  }
  + a {
    margin-left: 5.6rem;
  }
  svg {
    color: var(--green);
    font-size: 2.6rem;
    transition: color 0.2s;
    :hover {
      color: var(--green-lighter);
    }
  }
`;

export const MobileNav = styled.nav`
  display:  none;
  align-items: center;
  button {
    background: none;
    &:hover {
      svg {
        color: var(--green-lighter);
      }
    }
    svg {
      transition: color 0.2s linear;
      color: var(--green-darker);
    }
  }
  a {
    margin-left: 3rem;
  }

  @media (max-width:  900px)  {
    display: flex;
  }
`;

