import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  citizenShipTexts as pageTexts,
  ICitizenshipText,
} from "../../data/database";
import Button from "../Button";
import CurvedShape from "../Curved";
import ToggleCard from "../ToggleCard";
import { CallToAction, Container } from "./styles";

const Citizenship: React.FC = ({ children }) => {
  const history = useHistory();
  const [citizenshipTexts] = useState<ICitizenshipText[]>([...pageTexts]);
  const [currentOpenedText, setCurrentOpenedText] = useState<number>(1);

  return (
    <Container sectionColor="var(--grey)" id="Cidadania">
      {children}
      <h2 className="container">Cidadania Italiana</h2>
      <div className="sub-container">
        <div className="first-column">
          <h4>Vantagens de ser um cidadão italiano</h4>
          <p>
            A possibilidade de trabalho, estudo e moradia são alguns dos
            principais interesses de pessoas que realizam o processo de
            cidadania italiana. Além da relação de emprego tradicional, o
            cidadão italiano tem a sua disposição a possibilidade de participar
            de concursos públicos na Itália e na Europa como um todo.
            <br />
            <br />
            Podemos citar também, o acesso facilitado aos Estados Unidos e
            Canadá, bem como vistos de trabalho para Austrália e Nova Zelândia.
          </p>

          <h4>Como posso ajudar você no processo</h4>
          <p>
            Meu trabalho consiste em assessorar de forma responsável o processo
            de imigração e cidadania italiana. Atuo no processo como um todo,
            desde a identificação da linhagem com a busca de documentos, até o
            pedido de cidadania, na esfera administrativa e judicial tanto no
            Brasil quanto na Itália.
            <br />
            <br />
            Cada processo exige avaliação individual personalizada, para que se
            tenha o mapeamento do procedimento adequado ao caso concreto,
            observada as necessidades e o interesse do requerente. Minha atuação
            à frente do processo ocorre de acordo com as possibilidades legais e
            as necessidades do cliente. Desta forma, cada processo é único.
            <br />
            <br />A análise e execução são realizadas com total segurança
            jurídica, para que o cliente se torne um cidadão italiano/europeu de
            maneira lícita e regular.
          </p>
          <CallToAction>
            <b>
              Quer iniciar seu processo de cidadania italiana e não sabe por
              onde começar? Entre em contato comigo.
            </b>

            <Button
              onClick={async () => {
                await history.push("");
                history.push("/Contato");
              }}
            >
              Entrar em contato
            </Button>
          </CallToAction>
        </div>
        <div className="second-column">
          {citizenshipTexts.map((citizenShipText, index) => (
            <ToggleCard
              key={index}
              {...citizenShipText}
              open={index + 1 === currentOpenedText}
              handleChangeOpenCard={setCurrentOpenedText}
              cardCustomIndex={index + 1}
            />
          ))}
        </div>
      </div>
      <CurvedShape customColor="--green" />
    </Container>
  );
};
export default Citizenship;
