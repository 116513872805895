import styled, { css } from "styled-components";

import { ReactComponent as arrowUpIcon } from "../../assets/images/icons/arrowUp.svg";

export interface ISection {
  sectionColor: string;
}
export const Container = styled.section<ISection>`
  ${({ sectionColor }) => css`
    background-color:${sectionColor}};
  `}
  position:relative;

  svg {
    z-index: 20;
    &:not(:first-child) {
      margin-bottom: -1rem;
      margin-top: 13rem;
    }
  }

  h2 {
    color: var(--green-lighter);
    font-weight: 300;
    margin-bottom: 2.3rem;
    margin-top: 4.3rem;
    line-height: 6rem;
    @media (min-width: 1200px) {
      margin-bottom: 4rem;
    }
  }

  .sub-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: 1300px) {
      flex-wrap: nowrap;
      .first-column {
        margin-right: 9rem;
      }
    }
  }

  .first-column {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    max-width: 67.4rem;
    margin-bottom: 6rem;

    h4 {
      font-weight: 600;
      color: var(--green-darker);
    }

    p {
      color: var(--green-darker);
      font-size: 1.8rem;
      line-height: 2.8rem;
      margin-bottom: 2rem;
    }

    b + button {
      margin-top: 1.4rem;
    }

    strong {
      color: var(--green-darker);
      font-size: 2.2rem;
      font-weight: 400;
    }
    b {
      font-size: 1.8rem;
      font-weight: bold;
    }
    p + .address-wrapper {
      margin-top: 1rem;
    }
    .address-wrapper {
      h5 {
        color: var(--green-darker);

        line-height: 2.8rem;
        font-size: 2.2rem;
      }
      p {
        margin-bottom: 1rem;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        color: var(--green-darker);
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
      + .address-wrapper {
        margin-top: 5.7rem;
      }
    }

    .contact-whatsapp {
      margin-top: 5.6rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      b {
        margin-right: 1rem;
      }
      a {
        text-decoration: none;
        color: var(--green-darker);
        font-size: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        svg {
          margin-left: 1.46rem;
          margin-top: 0;
        }
      }
    }
  }

  .second-column {
    display: flex;
    width: 100%;
    flex-flow: column;
    max-width: 60.6rem;
  }
`;

export const GoToTop = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4.98rem;
  padding-bottom: 8.16rem;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const SVGArrowUp = styled(arrowUpIcon)`
  color: var(--brown-darker);
  animation: float 3.2s 1s linear infinite;
  transition: color 0.8s linear;
  height: 6rem;
  &:hover {
    cursor: pointer;
    color: var(--green-lighter);
    animation: none;
  }

  @media (max-width: 900px) {
    height: 4rem;
  }
`;
