import grabrielaPhoto from "../assets/images/Gabriela.jpg";
import jakielPhoto from "../assets/images/Jakiel.jpeg";
import kerlenPhoto from "../assets/images/Kerlen.jpeg";
import marcoPhoto from "../assets/images/Marco.jpeg";
import petersonPhoto from "../assets/images/Peterson.jpeg";
import stonePhoto from "../assets/images/Stone.jpg";

export interface IPrivacyText {
  cardTitle: string;
  cardText: string;
}

export interface ICitizenshipText extends IPrivacyText {
  citizenshipMethod: string;
}

export interface IReviewText {
  url: string;
  name: string;
  city: string;
  country: string;
  review: string;
}
export interface IpeaData {
  countryCode: string;
  countryName: string;
}
export const privacyTexts: IPrivacyText[] = [
  {
    cardTitle: `SEÇÃO 1 - INFORMAÇÕES GERAIS`,
    cardText: ` A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes do site http://fabriziabazana.com, com a finalidade de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.

    Esta Política de Privacidade aplica-se a todos os usuários e visitantes do site http://fabriziabazana.com e integra os Termos e Condições Gerais de Uso do OU aplicativo site de Burtet Bazana Sociedade Individual de Advocacia devidamente inscrita no CNPJ sob o nº 09.232.209/0001-55, situado em Rua 24 de Outubro, nº 1100/ sala 403, Bairro Moinhos de Vento - Porto Alegre/RS, doravante denominada Fabrízia Bazana.

    O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.`,
  },
  {
    cardTitle: `SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE`,
    cardText: ` Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma:

    Quando o usuário cria um cadastro na plataforma fabriziabazana.com: esses dados são os dados de identificação básicos, como: e-mail, nome completo, data e local de nascimento, histórico familiar, cidade de residência e profissão. A partir deles, podemos identificar o usuário e o visitante, além de garantir uma maior segurança e bem-estar às suas necessidade. Ficam cientes os usuários e visitantes de que seu perfil na plataforma  não estará acessível a todos demais usuários e visitantes.

    Quando um usuário e visitante acessa páginas do site fabriziabazana.com: as informações sobre interação e acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário e visitante. Estes dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário e visitante provêm, o navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.

    Por intermédio de terceiro: o site fabriziabazana.com recebe dados de terceiros, como LinkedIn e Google, quando um usuário faz login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.`,
  },
  {
    cardTitle: `SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE`,
    cardText: `
    Os dados pessoais do usuário e visitante recolhidos são os seguintes:

    Dados para a criação do cadastro de “Árvore Genealógica” no site fabriziabazana.com: e-mail, nome completo, data e local de nascimento, histórico familiar, cidade de residência e profissão.

    Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas na busca, recomendações, endereço de IP.

    Dados sensíveis: a plataforma poderá coletar os seguintes dados sensíveis do usuário - origem étnica ou racial e dados genéticos.

    Dados relacionados a contratos: diante da formalização do contrato de prestação de serviços entre a Burtet Bazana Sociedade Individual de Advocacia e o usuário. Poderão ser coletados e armazenados dados relativos à execução contratual, inclusive as comunicações realizadas entre a empresa e o usuário.
    
    `,
  },
  {
    cardTitle: `SEÇÃO 3.1 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE`,
    cardText: `
    Os dados pessoais do usuário e do visitante coletados e armazenados pelo site fabriziabazana.com tem por finalidade:

    Bem-estar do usuário e visitante: aprimorar o serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e fornecer funcionalidades específicas a depender das características básicas do usuário.

    Previsão do perfil do usuário: tratamento automatizado de dados pessoais para avaliar o uso na plataforma.

    Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da plataforma, exclusivo para usuários cadastrados. 

    Dados de contrato: conferir às partes segurança jurídica e facilitar a condução do atendimento.

    O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.
    `,
  },
  {
    cardTitle: `SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?`,
    cardText: `
    Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.

    Os dados podem ser removidos a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento.

    Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:

    I - cumprimento de obrigação legal ou regulatória pelo controlador;

    II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;

    III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;

    IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
    `,
  },
  {
    cardTitle: `SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS`,
    cardText: `
    A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.

    A plataforma não se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais.

    Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
    `,
  },
  {
    cardTitle: `SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS`,
    cardText: `
    Os dados cadastrados não são compartilhados publicamente em sistemas de busca e dentro da plataforma, sendo estes de uso exclusivo das partes responsáveis pelo tratamento de dados do site.
    `,
  },
  {
    cardTitle: `SEÇÃO 7 – COOKIES OU DADOS DE NAVEGAÇÃO`,
    cardText: `
    O usuário e o visitante do site fabriziabazana.com manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.

    O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do navegador. É possível redefinir o navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.
    `,
  },
  {
    cardTitle: `SEÇÃO 8 - CONSENTIMENTO`,
    cardText: `Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo com a presente Política de Privacidade.

    O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.
    
    O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato através do e-mail contato@fabriziabazana.com.
    `,
  },
  {
    cardTitle: `SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE`,
    cardText: `
    Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário e visitante revise-a com frequência.

    Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário e visitante demonstra sua concordância com as novas normas.
    
    Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser transferidas para os novos proprietários para que a permanência dos serviços oferecidos.
    
    `,
  },
  {
    cardTitle: `SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS`,
    cardText: `
    Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
    
    Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.
    `,
  },
];

export const reviewsTexts: IReviewText[] = [
  {
    url: `${kerlenPhoto}`,
    name: "Kerlen Costa ",
    city: "Porto Alegre",
    country: "Brasil",
    review: `Fabrizia é uma profissional extremamente inteligente, assertiva e prática. Tem pleno conhecimento da área em que atua, o que garante agilidade e precisão na resolução das questões que assume. É uma referência no meio jurídico quando o assunto é imigração e cidadania, fruto de um trabalho árduo realizado com propósito.`,
  },
  {
    url: `${jakielPhoto}`,
    name: "Jankiel Rohr",
    city: "Cork",
    country: "Irlanda",
    review: `Não tenho palavras para agradecer o trabalho fantástico que a Fabrízia fez por mim. Quando não tinha mais esperanças em obter a cidadania italiana, ela abraçou o meu processo e o chamou de filho! A Fabrízia mostrou toda a sua competência em fazer as coisas acontecerem. Recomendo de olhos fechados!`,
  },
  {
    url: `${grabrielaPhoto}`,
    name: "Gabriela Westphalen Favarini",
    city: "Londres",
    country: "Reino Unido",
    review: `O trabalho da Fabrízia é incrível! Ela é uma profissional muito dedicada, organizada e expert na área que atua. Ela me ajudou no meu processo, conduzindo tudo com muito profissionalismo e clareza. Ela está sempre disponível para tirar dúvidas e passar informações. Com certeza, uma profissional diferenciada que eu recomendo de olhos fechados!`,
  },
  {
    url: `${stonePhoto}`,
    name: "Stone Felix",
    city: "Hamburg",
    country: "Alemanha",
    review: `Fabrízia é uma profissional ágil, assertiva e correta. Em 2016 fiz meu processo para obtenção da cidadania italiana com o apoio da Fabrízia e tudo transcorreu conforme combinado no início. Todas as vezes que tive dúvidas, ou questões adicionais, foram respondidas prontamente. Recomendo o trabalho da Fabrízia, pois além do meu feedback positivo, já a recomendei a alguns amigos que também estão satisfeitos, como eu fiquei.`,
  },
  {
    url: `${marcoPhoto}`,
    name: "Marco Migliavacca",
    city: "Porto Alegre",
    country: "Brasil",
    review: `Se você precisar de serviços de direito internacional, em especial ligados à cidadania italiana, a Fabrízia é uma excelente profissional, séria e competente para te apoiar. Trabalha com estrutura no Brasil e na Itália oferecendo muita segurança nos processos.`,
  },
  {
    url: `${petersonPhoto}`,
    name: "Peterson Orcy ",
    city: "Florianópolis",
    country: "Brasil",
    review: `A Fabrízia superou todas as expectativas. O objetivo de virar cidadão italiano foi atingido, porém a experiência como cliente foi muito diferenciada. Ela domina a matéria com excelência, mostrou caminhos e alternativas, me deixou muito tranquilo durante o processo, e esteve sempre presente para responder perguntas, orientar e ajudar. Super profissional.`,
  },
];

export const citizenShipTexts: ICitizenshipText[] = [
  {
    cardTitle: `Processo administrativo realizado no Brasil*`,
    citizenshipMethod: `Convocados pelo consulado`,
    cardText: `O processo via administrativo no Brasil ocorre junto aos Consulados Italianos de residência do requerente. O tempo médio até o momento de obtenção do passaporte italiano, é de 10 a 15 anos. Esta modalidade requer uma longa espera, portanto é necessário aguardar que o órgão competente  processe os pedidos.

    A documentação deve cumprir a integralidade dos requisitos legais. Caso haja problemas documentais é necessário realizar a análise pontual da viabilidade e/ou alternativas autorizadas pela lei.
    `,
  },
  {
    cardTitle: `Processo administrativo realizado na Itália
    `,
    citizenshipMethod: `Via comune`,
    cardText: `O processo de cidadania realizado perante a comune italiana se baseia na possibilidade de fixar residência de maneira lícita e regular, mediante a permanência em solo italiano no prazo da lei. Como principal vantagem, podemos destacar a velocidade de conclusão e a agilidade na obtenção do da cidadania italiana.

    A viabilidade deste processo decorre da avaliação da documentação. Para isto, a documentação deve seguir a Legislação Italiana e as instruções associadas à comune.
    `,
  },
  {
    cardTitle: `Processo jurisdicionado realizado na Itália`,
    citizenshipMethod: `Via tribunal de Roma`,
    cardText: `O processo via judicial se caracteriza pela apresentação do pedido de cidadania italiana diretamente no Tribunal de Roma tendo à frente um advogado habilitado a atuar em território italiano, nos 3 graus de jurisdição. Tal modalidade pode ocorrer para pedidos relativos à fila de espera do consulado, filho natural ou via materna.

    Este processo não exige o deslocamento à Itália por parte do requerente e o tempo médio de realização do processo é de 24 meses. 
    
    A documentação deve seguir os exatos termos da Legislação Italiana, instruções normativas ministeriais e a jurisprudência.
    `,
  },
];

export const familyNames: string[] = [
  "ABRUZZI",
  "AIGA",
  "AJANI",
  "ALAGIA",
  "ALBERTI",
  "ALESSIO",
  "ALMA",
  "ALOISE",
  "ALQUATI",
  "AMENDOLA",
  "ANDELIERO",
  "ANDREOLI",
  "ANDRIANI",
  "ANNUNZIATO",
  "ANTONELLI",
  "ANTONIAZZI",
  "APPIO",
  "ARESI",
  "ARPINI",
  "BALDINI",
  "BALLISTA",
  "BARBARESCO",
  "BARUTTA",
  "BASSO",
  "BATISTUZZI",
  "BAU’",
  "BERNARDI",
  "BERNARDI",
  "BERNARDI",
  "BERNARDI",
  "BILLIERI",
  "BOLZON",
  "BORGATO",
  "BRUSTOLIN",
  "BURTET",
  "CADORE",
  "CALIARI",
  "CALLEGARO",
  "CARDONA",
  "CASANI",
  "CECON",
  "CHIARELLOTO",
  "CIABATTI",
  "COCCHIARELLA",
  "CODOL",
  "COMARON",
  "COMMAZZETTO",
  "CONTE",
  "CONTI",
  "CORDIOLI",
  "COSTABEBER",
  "CREMONESE",
  "CREMONESE",
  "DALLA VALLE",
  "DE PALMA",
  "DI BIASO",
  "FANTIN",
  "FERRARI",
  "FIN",
  "FOSCANINI",
  "FRANCESCHETTO",
  "GIANNOTTI",
  "GOLUSSI",
  "GRANATO",
  "GUAITA",
  "LAZZARINI",
  "LEONI",
  "LEPORONI",
  "LORENZI",
  "LORENZI",
  "MAGLIO DONIDA",
  "MANGANELLI",
  "MARCHI",
  "MARIN",
  "MARION",
  "MARTINO",
  "MEDOZZI",
  "MENEGON",
  "MILANESE",
  "MILANI",
  "MOCELLIN",
  "MOMI",
  "MONTERA",
  "MORELLO",
  "MORETTI",
  "MOSCHIONI",
  "MUCCILLO",
  "OLIVIERI",
  "PAILLACE",
  "PALA",
  "PALADINO",
  "PALMA",
  "PANCERI",
  "PANOZZO",
  "PANSARDI",
  "PAPETTI",
  "PAROLINI",
  "PARPAGNOLI",
  "PARUZZOLO",
  "PASINI",
  "PAULETTI",
  "PECORARO",
  "PERIN",
  "PETUCCO",
  "PICOLLI",
  "PICOLLI",
  "PIRONDI",
  "PIRONIO",
  "PISARRA",
  "PITTONDO",
  "PIVETTA",
  "PLASTINA",
  "PREVEDELLO",
  "PUERARI",
  "PUNTEL",
  "RACHELE",
  "RAFFANTI",
  "RAFFANTI",
  "RAGUZZONI",
  "RECH",
  "RICARDI",
  "RODOLFO",
  "ROMAN",
  "ROMAN",
  "ROMAN-LENARD",
  "RORATO",
  "ROSA",
  "ROSSETTO",
  "ROSSI",
  "ROSSONI",
  "ROTA GRAZIOSI",
  "ROVEDA",
  "RUGGIERO",
  "SAGRILLO",
  "SALATA",
  "SANGOI",
  "SANGOI",
  "SANGOI",
  "SANTAMARIA",
  "SANTANGELO",
  "SANTINI",
  "SANTINI",
  "SASSO",
  "SAVADEGO",
  "SBRAVATI",
  "SCAFFARO",
  "SCAFFARO",
  "SCAGLIA",
  "SCARTON",
  "SCHIRATO",
  "SECCO",
  "SEGATO",
  "SERENA",
  "SFFOGIA",
  "SLAVIERO",
  "SOLLITO",
  "SORMAVILLA",
  "SOSTER",
  "SPADETTO",
  "SPEROTTO",
  "STEFFENEL",
  "STELLA",
  "STRAPPAZZON",
  "SUSANNA",
  "TAGLIACANE VANELLI",
  "TAGLIARO",
  "TAGLIARO",
  "TAGLIARO",
  "TAGLIARO",
  "TAGLIARO",
  "TAGLIATI",
  "TANCREDI",
  "TANCREDI",
  "TANCREDI",
  "TELLINI",
  "TESSARI",
  "TIBOLA",
  "TOGNI",
  "TOIGO",
  "TONDELLO",
  "TONELLO",
  "TONIOLO",
  "TORELLA",
  "TOSI",
  "TRAMONTINA CHIANIPER",
  "TREMEA",
  "TREMEA",
  "TREMEA",
  "TRENTIN",
  "TRENTIN",
  "TREVISAN",
  "UNFER",
  "UNGARETTI",
  "VALCARENGHI",
  "VEDOVATO",
  "VENCATO",
  "VERRI",
  "VERZONI",
  "VIAN",
  "ZAGO",
  "ZANCANARO",
  "ZANDONA",
  "ZANDONA",
  "ZANELLA",
  "ZASSO",
  "ZIGON",
  "ZOCCARATO",
  "ZOLIN",
];

export const countries: IpeaData[] = [
  {
    countryCode: "AFG",
    countryName: "Afeganistão",
  },
  {
    countryCode: "ZAF",
    countryName: "África do Sul",
  },
  {
    countryCode: "DEU",
    countryName: "Alemanha",
  },
  {
    countryCode: "LATI",
    countryName: "América Latina",
  },
  {
    countryCode: "AGO",
    countryName: "Angola",
  },
  {
    countryCode: "SAU",
    countryName: "Arábia Saudita",
  },
  {
    countryCode: "DZA",
    countryName: "Argélia",
  },
  {
    countryCode: "ARG",
    countryName: "Argentina",
  },
  {
    countryCode: "AUS",
    countryName: "Austrália",
  },
  {
    countryCode: "AUT",
    countryName: "Áustria",
  },
  {
    countryCode: "BEL",
    countryName: "Bélgica",
  },
  {
    countryCode: "BOL",
    countryName: "Bolívia",
  },
  {
    countryCode: "BRA",
    countryName: "Brasil",
  },
  {
    countryCode: "CPV",
    countryName: "Cabo Verde",
  },
  {
    countryCode: "CAN",
    countryName: "Canadá",
  },
  {
    countryCode: "CHL",
    countryName: "Chile",
  },
  {
    countryCode: "CHN",
    countryName: "China",
  },
  {
    countryCode: "SGP",
    countryName: "Cingapura",
  },
  {
    countryCode: "COL",
    countryName: "Colômbia",
  },
  {
    countryCode: "COG",
    countryName: "Congo",
  },
  {
    countryCode: "KOR",
    countryName: "Coréia do Sul",
  },
  {
    countryCode: "DNK",
    countryName: "Dinamarca",
  },
  {
    countryCode: "ARE",
    countryName: "Emirados Árabes Unidos",
  },
  {
    countryCode: "ECU",
    countryName: "Equador",
  },
  {
    countryCode: "ESP",
    countryName: "Espanha",
  },
  {
    countryCode: "USA",
    countryName: "Estados Unidos",
  },
  {
    countryCode: "RUS",
    countryName: "Federação Russa",
  },
  {
    countryCode: "FIN",
    countryName: "Finlândia",
  },
  {
    countryCode: "FRA",
    countryName: "França",
  },
  {
    countryCode: "GBR",
    countryName: "Grã-Bretanha (Reino Unido, UK)",
  },
  {
    countryCode: "GRC",
    countryName: "Grécia",
  },
  {
    countryCode: "GNB",
    countryName: "Guiné-Bissau",
  },
  {
    countryCode: "HTI",
    countryName: "Haiti",
  },
  {
    countryCode: "NLD",
    countryName: "Holanda",
  },
  {
    countryCode: "HKG",
    countryName: "Hong Kong",
  },
  {
    countryCode: "HUN",
    countryName: "Hungria",
  },
  {
    countryCode: "IND",
    countryName: "Índia",
  },
  {
    countryCode: "IRN",
    countryName: "Irã",
  },
  {
    countryCode: "IRL",
    countryName: "Irlanda",
  },
  {
    countryCode: "ISL",
    countryName: "Islândia",
  },
  {
    countryCode: "ISR",
    countryName: "Israel",
  },
  {
    countryCode: "ITA",
    countryName: "Itália",
  },
  {
    countryCode: "JPN",
    countryName: "Japão",
  },
  {
    countryCode: "LUX",
    countryName: "Luxemburgo",
  },
  {
    countryCode: "MAC",
    countryName: "Macau",
  },
  {
    countryCode: "MYS",
    countryName: "Malásia",
  },
  {
    countryCode: "MAR",
    countryName: "Marrocos",
  },
  {
    countryCode: "MEX",
    countryName: "Mexico",
  },
  {
    countryCode: "MOZ",
    countryName: "Moçambique",
  },
  {
    countryCode: "WRLD",
    countryName: "Mundial",
  },
  {
    countryCode: "MMR",
    countryName: "Myanma (Ex-Burma)",
  },
  {
    countryCode: "NGA",
    countryName: "Nigéria",
  },
  {
    countryCode: "NOR",
    countryName: "Noruega",
  },
  {
    countryCode: "NZL",
    countryName: "Nova Zelândia",
  },
  {
    countryCode: "INDU",
    countryName: "Países industrializados",
  },
  {
    countryCode: "PRY",
    countryName: "Paraguai",
  },
  {
    countryCode: "PER",
    countryName: "Peru",
  },
  {
    countryCode: "PRT",
    countryName: "Portugal",
  },
  {
    countryCode: "QAT",
    countryName: "Qatar",
  },
  {
    countryCode: "CZE",
    countryName: "República Tcheca",
  },
  {
    countryCode: "STP",
    countryName: "São Tomé e Príncipe",
  },
  {
    countryCode: "SWE",
    countryName: "Suécia",
  },
  {
    countryCode: "CHE",
    countryName: "Suíça",
  },
  {
    countryCode: "THA",
    countryName: "Tailândia",
  },
  {
    countryCode: "TLS",
    countryName: "Timor Leste (Ex-East Timor)",
  },
  {
    countryCode: "URY",
    countryName: "Uruguai",
  },
  {
    countryCode: "VEN",
    countryName: "Venezuela",
  },
  {
    countryCode: "ZEUR",
    countryName: "Zona do Euro",
  },
];
