import styled from "styled-components";

export const Main = styled.main`
  overflow: hidden;
  .curve-darker {
    margin-bottom: -2rem;
  }
  .wrapper-tower {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      position: relative;
      align-items: center;
      img {
        position: relative;
        bottom: 0px;
        right: 0px;
      }
    }

    @media (max-width: 1140px) {
      display: none;
    }

    @media (min-width: 1140px) {
      #desktop-img {
        display: block;
      }
    }

    @media (min-width: 1500) {
      #desktop-img {
        transform: scale(1.3);
      }
    }
  }
`;

export const HeroSection = styled.section`
  display: grid;
  position: relative;
`;

export const TextsWrapper = styled.div`
  display: flex;
  grid-gap: 1.5rem;
  flex-flow: column;
  width: 100%;
  height: fit-content;
  margin-bottom: 50%;

  p {
    max-width: 45.5rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: 2rem;
  }

  button {
    margin-top: 2rem;
    z-index: 30;
  }

  @media (min-width: 900px) {
    margin-top: 12rem;
  }
  @media (min-width: 1020px) {
    margin-bottom: 0;
  }
`;

export const HeroImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -4rem;

  img {
    z-index: -10;
    position: relative;
    height: auto;
    width: 100%;
    max-width: 76.8rem;
    right: -27%;
    bottom: -45%;
  }
  @media (min-width: 1140px) {
    img {
      display: none;
    }
  }
`;

export const HeroWave = styled.div`
  position: absolute;
  width: calc(100vw + 8%);
  height: 100%;
  bottom: -38%;
  img {
    z-index: -5;
    position: relative;
    height: auto;
    width: 100%;
    max-width: 100%;
    right: 0;
    bottom: -45%;
  }
  @media (min-width: 1020px) {
    img {
      right: 10%;
      bottom: -45%;
    }
  }
`;
