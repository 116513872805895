import styled, { css } from "styled-components";
import { ReactComponent as logo } from "../../assets/images/icons/logo.svg";

export const SVGLogo = styled(logo)`
  max-width:22.6rem;
  height: 4.6rem;
  color: var(--grey-darker);
  ${({ color }) =>
    color &&
    css`
      color: ${`var(${color})`};
    `}
    @media (min-width: 700px) {
      height: 6.816rem;

    }
`;
