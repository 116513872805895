import React from "react";
import { IReviewText } from "../../data/database";

import { Box, ReviewImage, ReviewText } from "./styles";

interface IReviewProps extends IReviewText {}

const Review: React.FC<IReviewProps> = ({
  url,
  name,
  city,
  country,
  review,
}) => {
  return (
    <Box>
      <ReviewImage>
        <img src={url} alt={name} />
      </ReviewImage>
      <ReviewText>
        <div className="title">
          <b>{name}</b>{" "}
          <span>
            {" "}
            - {city}, {country}
          </span>
        </div>
        <p>
          <q>{review}</q>
        </p>
      </ReviewText>
    </Box>
  );
};

export default Review;
