import React, {
  ChangeEvent,
  SelectHTMLAttributes,
  useRef,
  useState,
} from "react";
import { SelectBlock } from "./styles";

import {countries as IpeaCountries, IpeaData} from '../../data/database'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  off?: boolean;
  handleSelect: (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

const Select: React.FC<SelectProps> = ({
  label,
  name,
  off,
  handleSelect,
  ...rest
}) => {
  const [countries,] = useState<IpeaData[]>(IpeaCountries);
  const selectRef = useRef<HTMLSelectElement>({} as HTMLSelectElement);

  

  return (
    <SelectBlock off={off}>
      <label htmlFor={name}>{label}</label>
      <select
        ref={selectRef}
        defaultValue=""
        id={name}
        name={name}
        {...rest}
        onChange={(e) => handleSelect(e)}
      >
        <option value="" disabled hidden></option>
        {countries.map(({countryCode,countryName}, index) => {
          return (
            <option key={`${countryCode}-${index}`} value={countryName}>
              {countryCode}
            </option>
          );
        })}
      </select>
    </SelectBlock>
  );
};

export default Select;
