import React from "react";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import Logo from "../Logo";
import { FooterWrapper, LinkInIcon } from "./styles";

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <div className="container">
        <Logo className="logo-footer" customColor="--brown" />

        <nav className="media-links">
          <LinkInIcon
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/fabriziabazana/"
          >
            <FaLinkedinIn />
          </LinkInIcon>
          <LinkInIcon
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/5551993552839"
          >
            <FaWhatsapp />
          </LinkInIcon>
        </nav>

        <nav className="links">
          <a href="/Privacidade">Política de Privacidade</a>
          <a href="/Privacidade">Todos os direitos reservados</a>
          <a
            href="https://www.priscilafelippetto.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Desenhado por Priscila Felippetto
          </a>
        </nav>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
