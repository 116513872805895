import React from "react";
import { SVGLogo } from "./styles";

interface ILogoProps extends React.SVGAttributes<SVGElement> {
  customColor?: string;
}

const Logo: React.FC<ILogoProps> = ({ customColor, ...rest }) => {
  return <SVGLogo color={customColor} {...rest} />;
};

export default Logo;
