import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 3.49rem;
  max-width: 100%;
  width: 98.5rem;
  border-radius: 1.1rem;
  margin: 0 auto;
  align-items: flex-start;
  background-color: var(--white);
  position: relative;
  top: -25rem;
  margin-bottom: -20rem;

  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    width: 92.1%;
    flex-flow: column;
    align-items: center;
    top: -12rem;
    padding: 2.4rem 2.4rem 3.1rem 2.3rem;
    margin-bottom: -9rem;
  }
`;
export const ReviewImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 23.714rem;
    height: 21.7rem;
    border-radius: 1.1rem 20.4rem 13.3rem;
  }

  @media (max-width: 900px) {
    img {
      width: 9rem;
      height: auto;
      border-radius: 50%;
      position: absolute;
      top: -4.5rem;
    }
  }
`;
export const ReviewText = styled.div`
  display: flex;
  margin-top: 3.8rem;
  align-items: start;
  flex-flow: column;
  .title {
    b,
    span {
      color: var(--green-lighter);
      line-height: 2.8rem;
    }
    b {
      font-size: 2.1rem;
      font-weight: 600;
    }
    span {
      font-size: 1.8rem;
      font-weight: regular;
    }
  }
  p {
    margin-top: 1rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-style: italic;
    margin-right: 4rem;
    margin-bottom: 2.9rem;
    color: var(--green-darker);
  }
  @media (max-width: 900px) {
    .title {
      display: flex;
      flex-flow: column;
    }
    p {
      margin-right: 0rem;
    }
  }
`;
