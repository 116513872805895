import styled, { css } from "styled-components";
import { ReactComponent as curvedShape } from "../../assets/images/AboutTopWave.svg";

export const SVGCurvedShape = styled(curvedShape)`
  color: var(--green);
  ${({ color }) =>
    color &&
    css`
      color: ${`var(${color})`};
    `}
  width:100%;
`;
