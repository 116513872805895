import React, { useState } from "react";

import { PrivacyWrapper, Main } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ToggleCard from "../../components/ToggleCard";

import {
  privacyTexts as initialPageTexts,
  IPrivacyText,
} from "../../data/database";

const Privacy: React.FC = () => {
  const [privacyTexts] = useState<IPrivacyText[]>(initialPageTexts);

  return (
    <PrivacyWrapper>
      <Header />

      <Main>
        <h2 className="container">Política de Privacidade.</h2>
        {privacyTexts.map((privacyText, index) => (
          <div className="sub-container" key={index}>
            <ToggleCard {...privacyText} open={false} />
          </div>
        ))}
      </Main>
      <Footer />
    </PrivacyWrapper>
  );
};

export default Privacy;
