import React from "react";
import { Section } from "./styles";
import CurvedShape from "../Curved";
import SearchBar from "../SearchBar";

const Achievements: React.FC = ({ children }) => {
  return (
    <Section sectionColor="var(--green)" id="Acervo">
      {children}
      <h2 className="container">Nosso acervo</h2>
      <div className="sub-container">
        <div className="first-column">
          <p>
            Durante os anos de trabalho e dedicação, formamos um banco de dados
            com centenas de sobrenomes. A partir disso, montamos um acervo com
            os sobrenomes dos processos já finalizados com êxito pela equipe
            Fabrízia Bazana e que agora estamos disponibilizando para você
            consultar.
          </p>
          <p>
            Para saber se seu sobrenome consta em nosso acervo, você deve
            preencher nosso campo de pesquisa abaixo. Será realizada uma busca
            automática para identificar se já existem outras pessoas com o mesmo
            sobrenome italiano que tenham realizado o processo conosco.
          </p>
        </div>
        <div className="second-column">
          <SearchBar />
        </div>
      </div>

      <CurvedShape customColor="--grey" />
    </Section>
  );
};

export default Achievements;
