import React from "react";
import CurvedShape from "../Curved";
import { Container, GeometricShape, Profile } from "./styles";
import { FaLinkedinIn } from "react-icons/fa";

import ProfilePhoto from "../../assets/images/Profile.png";

const About: React.FC = () => {
  return (
    <Container sectionColor="var(--green)" id="Sobre">
      <h2 className="container">Sobre</h2>
      <div className="sub-container">
        <div className="first-column">
          <Profile>
            <div className="profile-image-wrapper">
              <img src={ProfilePhoto}  alt="Foto de perfil - Fabrizia Bazana" />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/fabriziabazana/"
              >
                <FaLinkedinIn color="var(--green-lighter)"  size={20} />
              </a>
            </div>
            <h4>Fabrízia Bazana</h4>
          </Profile>
          <GeometricShape />
        </div>
        <div className="second-column">
          <h4>Fabrízia Bazana</h4>
          <p>
            Atuo há 15 anos com direito internacional, realizando processos de
            reconhecimento de cidadania para imigração, investimentos e
            contratos internacionais. Conto com uma equipe extremamente
            preparada para trazer as melhores soluções aos nossos clientes.
            Nosso trabalho é baseado na transparência e legalidade.
          </p>
          <strong>
            Meu compromisso é oferecer o processo de cidadania de forma segura e
            responsável, através das soluções legais.
          </strong>
          <p>
            Sou advogada (certificada pelas Ordens dos Advogados de Portugal sob
            o número 60794P, e do Brasil sob o número 43.094); Especialista em
            processos de reconhecimento de Cidadania Italiana para Imigração,
            Investimentos e Contratos Internacionais; Mestre em Direito e
            Negócios Internacionais pela Universidad Europea del Atlántico em
            Cantabria, Espanha.
          </p>
        </div>
      </div>
      <CurvedShape customColor="--grey" />
    </Container>
  );
};
export default About;
