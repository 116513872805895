import styled, { css } from "styled-components";
interface InputBlockProps {
  off?: boolean;
}

export const InputBlock = styled.div<InputBlockProps>`
  background: var(--grey);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 1.3rem 2.1rem 1rem 2.1rem;
  transition: opacity 0.2s linear;

  height: 5rem;

  + div {
    margin-top: 1rem;
  }

  label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--green-darker);
    cursor: pointer;
    min-width: fit-content;
  }
  input {
    padding-left: 0.5rem;
    border: 0;
    background: none;
    outline: none;

    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    &:disabled {
      opacity: 0.2;
      background: var(--grey-darker);
      pointer-events: none;
    }

    &::placeholder {
      color: var(--grey-darker);
    }

    ::-webkit-calendar-picker-indicator {
      opacity: 0.21;
      cursor: pointer;
    }

    ${({ off }) =>
      off &&
      css`
        color: transparent;
      `};
  }
  > svg {
    margin: 0px !important;
  }
  button {
    background-color: transparent;
  }

  ${({ off }) =>
    off &&
    css`
      opacity: 0.4;
    `};
`;
