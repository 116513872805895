import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { useParams } from "react-router";
import Logo from "../Logo";
import { MenuMobile } from "../MenuMobile";
import { Container, LinkInIcon, LinkText, MobileNav } from "./styles";

interface IParamsProps {
  id: string;
}

const Header: React.FC = () => {
  const { id } = useParams<IParamsProps>();

  const [fixed, setFixed] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const headerRef = useRef({} as HTMLElement);

  const handleCloseMenuMobile = useCallback(() => {
    setShowMobileMenu(false);
  }, []);
  const handleOpenMenuMobile = useCallback(() => {
    setShowMobileMenu(true);
  }, []);

  const handleScroll = useCallback(
    (destinationId: string) => {
      const destinationElement = document.querySelector(`#${destinationId}`);

      destinationElement?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      handleCloseMenuMobile();
    },
    [handleCloseMenuMobile]
  );

  useEffect(() => {
    function handleFixHeader() {
      const { current } = headerRef;
      setFixed(window.scrollY > current.offsetHeight);
    }

    window.addEventListener("scroll", handleFixHeader);

    return function cleanupListenerFixHeader() {
      window.removeEventListener("scroll", handleFixHeader);
    };
  }, []);

  useEffect(() => {
    if (!!id && id !== "privacidade") {
      handleScroll(id);
    }
  }, [handleScroll, id]);

  return (
    <>
      <div className={showMobileMenu ? "hide-me" : ""}>
        <Container ref={headerRef} id="header">
          <div className="container">
            <a href="/">
              <Logo customColor="--green" />
            </a>
            <nav className="links">
              <LinkText href="/Sobre">Sobre</LinkText>
              <LinkText href="/Cidadania">Cidadania Italiana</LinkText>
              <LinkText href="/Acervo">Nosso acervo</LinkText>
              <LinkText href="/Contato">Contato</LinkText>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/fabriziabazana/"
              >
                <FaLinkedinIn />
              </LinkInIcon>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5551993552839"
              >
                <FaWhatsapp />
              </LinkInIcon>
            </nav>

            <MobileNav>
              <button type="button" onClick={handleOpenMenuMobile}>
                <FiMenu size={30} />
              </button>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5551993552839"
                id="WhatsIcon"
              >
                <FaWhatsapp />
              </LinkInIcon>
            </MobileNav>
          </div>
        </Container>

        <Container className={fixed ? "header-fixed" : "header-fixed-hidden"}>
          <div className="container">
            <a href="/">
              <Logo customColor="--green" />
            </a>
            <nav className="links">
              <LinkText href="Sobre">Sobre</LinkText>
              <LinkText href="Cidadania">Cidadania Italiana</LinkText>
              <LinkText href="Acervo">Nosso acervo</LinkText>
              <LinkText href="Contato">Contato</LinkText>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/fabriziabazana/"
              >
                <FaLinkedinIn />
              </LinkInIcon>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5551993552839"
              >
                <FaWhatsapp />
              </LinkInIcon>
            </nav>
            <MobileNav>
              <button type="button" onClick={handleOpenMenuMobile}>
                <FiMenu size={30} />
              </button>
              <LinkInIcon
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/5551993552839"
                id="WhatsIcon"
              >
                <FaWhatsapp />
              </LinkInIcon>
            </MobileNav>
          </div>
        </Container>
      </div>

      {showMobileMenu && (
        <MenuMobile handleCloseMenuMobile={handleCloseMenuMobile} />
      )}
    </>
  );
};

export default Header;
