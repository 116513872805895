import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  background-color: var(--green-lighter);
  color: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 1.1rem;
  width: fit-content;
  padding: 2.1rem 4rem;
  font-size: 1.8rem;

  transition: background-color 0.2s linear;
  &:hover {
    cursor: pointer;
    background-color: var(--brown-darker);
  }
  &:disabled {
    background-color: var(--grey-darker);
  }

  &.secondary {
    background-color: var(--brown-lighter);
    color: var(--brown-darker);
    &:hover {
      background-color: var(--brown-darker);
      color: var(--brown-lighter);
    }
  }

  @media (max-width: 600px) {
    padding: 1.7rem 4rem;
    font-size: 1.6rem;
  }
`;
