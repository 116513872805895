import styled, { css } from "styled-components";

import { ReactComponent as toggleIcon } from "../../assets/images/icons/toggleIcon.svg";
interface ICardContentProps {
  isOpen: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 0.9fr 0.1fr;
    align-items: center;
    padding: 3.6rem 3.8rem;
    outline: none;
    border: none;
    background-color: var(--green);
    border-radius: 1.1rem;

    .title-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      line-height: 2.8rem;

      p {
        color: var(--white);
        font-size: 1.8rem;
        text-align: left;
      }
    }
    h4 {
      color: var(--white);
      font-size: 1.8rem;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
    }
  }
`;

export const SVGtoggleIcon = styled(toggleIcon)`
  transition: transform 1.4s;
  transform: rotate(180deg);
  color: var(--green-lighter);
  margin: 0 0 0 2rem !important;
  justify-self: flex-end;
  align-self: center;
  min-width: 6rem;

  &.open {
    transform: rotate(0deg);
  }
  &:hover {
    color: var(--green-lighter);
  }
`;

export const ContentCard = styled.div<ICardContentProps>`
  visibility: hidden;
  height: 0;
  width: 100%;
  display: none;

  ${(props) =>
    props.isOpen &&
    css`
      display: flex;
      animation: showInfos 1.4s ease;
      visibility: visible;
      height: auto;
    `}

  p {
    background-color: var(--white);
    color: var(--green-darker);
    font-size: 1.6rem;
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding: 2.6rem 3.6rem 3.2rem 3.1rem;
    width: 100%;
    height: auto;
    white-space: pre-line;
  }

  @keyframes showInfos {
    from {
      transform: translateY(-2%);
      opacity: 0.65%;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;
