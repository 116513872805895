import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import api from "../../api";
import checkIcon from "../../assets/images/icons/check.svg";
import Button from "../Button";
import { Input } from "../Input";
import Select from "../Select";
import {
  ContentCard,
  FieldGroupRadio,
  FormFragment,
  PrivacyCheck,
  Step,
  Steps,
  SubmittedFeedback,
  Wrapper,
} from "./styles";

export interface FormProps {
  fullName: string;
  email: string;
  phone: string;
  birthday: string;
  address: string;
  city: string;
  country: string;
  labor: string;
  married: string;
  marriedWith: string;
  marriedCity: string;
  marriedCountry: string;
  marriedDate: string;
  sons: string;
  sonsAges: string;
  privacy: string;
  parentFullName: string;
  parentBirthday: string;
  parentBornCity: string;
  parentCountry: string;
  parentMarried: string;
  parentMarriedWith: string;
  parentMarriedCity: string;
  parentMarriedCountry: string;
  parentMarriedDate: string;
  parentDead: string;
  parentDeadDate: string;
  parentDeadCity: string;
  parentDeadCountry: string;
  grandParentFullName: string;
  grandParentBirthday: string;
  grandParentBornCity: string;
  grandParentCountry: string;
  grandParentMarried: string;
  grandParentMarriedWith: string;
  grandParentMarriedCity: string;
  grandParentMarriedCountry: string;
  grandParentMarriedDate: string;
  grandParentDead: string;
  grandParentDeadDate: string;
  grandParentDeadCity: string;
  grandParentDeadCountry: string;
  greatGrandParentFullName: string;
  greatGrandParentBirthday: string;
  greatGrandParentBornCity: string;
  greatGrandParentCountry: string;
  greatGrandParentMarried: string;
  greatGrandParentMarriedWith: string;
  greatGrandParentMarriedCity: string;
  greatGrandParentMarriedCountry: string;
  greatGrandParentMarriedDate: string;
  greatGrandParentDead: string;
  greatGrandParentDeadDate: string;
  greatGrandParentDeadCity: string;
  greatGrandParentDeadCountry: string;
  greatGreatGrandParentFullName: string;
  greatGreatGrandParentBirthday: string;
  greatGreatGrandParentBornCity: string;
  greatGreatGrandParentCountry: string;
  greatGreatGrandParentMarried: string;
  greatGreatGrandParentMarriedWith: string;
  greatGreatGrandParentMarriedCity: string;
  greatGreatGrandParentMarriedCountry: string;
  greatGreatGrandParentMarriedDate: string;
  greatGreatGrandParentDead: string;
  greatGreatGrandParentDeadDate: string;
  greatGreatGrandParentDeadCity: string;
  greatGreatGrandParentDeadCountry: string;
}

const formInitialState = {
  fullName: "",
  email: "",
  phone: "",
  birthday: "",
  address: "",
  city: "",
  country: "",
  labor: "",
  married: "",
  marriedWith: "",
  marriedCity: "",
  marriedCountry: "",
  marriedDate: "",
  sons: "",
  sonsAges: "",
  privacy: "",
  parentFullName: "",
  parentBirthday: "",
  parentBornCity: "",
  parentCountry: "",
  parentMarried: "",
  parentMarriedWith: "",
  parentMarriedCity: "",
  parentMarriedCountry: "",
  parentMarriedDate: "",
  parentDead: "",
  parentDeadDate: "",
  parentDeadCity: "",
  parentDeadCountry: "",
  grandParentFullName: "",
  grandParentBirthday: "",
  grandParentBornCity: "",
  grandParentCountry: "",
  grandParentMarried: "",
  grandParentMarriedWith: "",
  grandParentMarriedCity: "",
  grandParentMarriedCountry: "",
  grandParentMarriedDate: "",
  grandParentDead: "",
  grandParentDeadDate: "",
  grandParentDeadCity: "",
  grandParentDeadCountry: "",
  greatGrandParentFullName: "",
  greatGrandParentBirthday: "",
  greatGrandParentBornCity: "",
  greatGrandParentCountry: "",
  greatGrandParentMarried: "",
  greatGrandParentMarriedWith: "",
  greatGrandParentMarriedCity: "",
  greatGrandParentMarriedCountry: "",
  greatGrandParentMarriedDate: "",
  greatGrandParentDead: "",
  greatGrandParentDeadDate: "",
  greatGrandParentDeadCity: "",
  greatGrandParentDeadCountry: "",
  greatGreatGrandParentFullName: "",
  greatGreatGrandParentBirthday: "",
  greatGreatGrandParentBornCity: "",
  greatGreatGrandParentCountry: "",
  greatGreatGrandParentMarried: "",
  greatGreatGrandParentMarriedWith: "",
  greatGreatGrandParentMarriedCity: "",
  greatGreatGrandParentMarriedCountry: "",
  greatGreatGrandParentMarriedDate: "",
  greatGreatGrandParentDead: "",
  greatGreatGrandParentDeadDate: "",
  greatGreatGrandParentDeadCity: "",
  greatGreatGrandParentDeadCountry: "",
};

export interface IResponse {
  message: string;
  step: number;
}

const ContactForm: React.FC = () => {
  const formReference = useRef<HTMLFormElement>({} as HTMLFormElement);

  const [formData, setFormData] = useState<FormProps>({
    ...formInitialState,
  } as FormProps);

  const [currentStep, setCurrentStep] = useState(1);
  const [visitedSteps, setVisitedSteps] = useState<number[]>([1]);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);

  const [firstFragmentValid, setFirstFragmentValid] = useState<boolean>(false);
  const [secondFragmentValid, setSecondFragmentValid] = useState<boolean>(
    false
  );
  const [thirdFragmentValid, setThirdFragmentValid] = useState<boolean>(false);
  const [fourthFragmentValid, setFourthFragmentValid] = useState<boolean>(
    false
  );
  const [fifthFragmentValid, setFifthFragmentValid] = useState<boolean>(false);

  const [isMarried, setIsMerried] = useState<boolean>(false);
  const [haveSons, setHaveSons] = useState<boolean>(false);
  const [parentIsMarried, setParentIsMerried] = useState<boolean>(false);
  const [grandParentIsMarried, setGrandParentIsMerried] = useState<boolean>(
    false
  );
  const [greatGrandParentIsMarried, setGreatGrandParentIsMerried] = useState<
    boolean
  >(false);
  const [
    greatGreatGrandParentIsMarried,
    setGreatGreatGrandParentIsMerried,
  ] = useState<boolean>(false);

  const [deadParent, setDeadParent] = useState<boolean>(false);
  const [deadGrandParent, setDeadGrandParent] = useState<boolean>(false);
  const [deadGreatGrandParent, setDeadGreatGrandParent] = useState<boolean>(
    false
  );
  const [deadGreatGreatGrandParent, setDeadGreatGreatGrandParent] = useState<
    boolean
  >(false);

  const validateFirstFragment = useCallback(() => {
    let validationResult = true;
    const {
      fullName,
      email,
      phone,
      birthday,
      address,
      city,
      country,
      labor,
      married,
      marriedWith,
      marriedCity,
      marriedCountry,
      marriedDate,
      sons,
      sonsAges,
      privacy,
    } = formData;

    validationResult = validationResult && privacy !== "";

    validationResult = validationResult && (sons === "sim" || sons === "não");

    if (haveSons) {
      validationResult = validationResult && sonsAges !== "";
    }

    if (isMarried) {
      validationResult =
        validationResult &&
        (marriedWith && marriedCity && marriedCountry && marriedDate) !== "";
    }
    validationResult =
      validationResult && (married === "sim" || married === "não");

    validationResult =
      validationResult &&
      (fullName &&
        email &&
        phone &&
        birthday &&
        address &&
        city &&
        country &&
        labor) !== "";

    setFirstFragmentValid(validationResult);
  }, [formData, haveSons, isMarried]);

  const validateSecondFragment = useCallback(() => {
    let validationResult = true;
    const {
      parentFullName,
      parentBirthday,
      parentBornCity,
      parentCountry,
      parentMarried,
      parentMarriedWith,
      parentMarriedCity,
      parentMarriedCountry,
      parentMarriedDate,
      parentDead,
      parentDeadDate,
      parentDeadCity,
      parentDeadCountry,
    } = formData;

    if (deadParent) {
      validationResult =
        validationResult &&
        (parentDeadDate && parentDeadCity && parentDeadCountry) !== "";
    }

    validationResult =
      validationResult && (parentDead === "sim" || parentDead === "não");

    if (parentIsMarried) {
      validationResult =
        validationResult &&
        (parentMarried &&
          parentMarriedWith &&
          parentMarriedCity &&
          parentMarriedCountry &&
          parentMarriedDate) !== "";
    }

    validationResult =
      validationResult && (parentMarried === "sim" || parentMarried === "não");

    validationResult =
      validationResult &&
      (parentFullName && parentBirthday && parentBornCity && parentCountry) !==
        "";

    setSecondFragmentValid(validationResult);
  }, [formData, parentIsMarried, deadParent]);

  const validateThirdFragment = useCallback(() => {
    let validationResult = true;
    const {
      grandParentFullName,
      grandParentBirthday,
      grandParentBornCity,
      grandParentCountry,
      grandParentMarried,
      grandParentMarriedWith,
      grandParentMarriedCity,
      grandParentMarriedCountry,
      grandParentMarriedDate,
      grandParentDead,
      grandParentDeadDate,
      grandParentDeadCity,
      grandParentDeadCountry,
    } = formData;

    if (deadGrandParent) {
      validationResult =
        validationResult &&
        (grandParentDeadDate &&
          grandParentDeadCity &&
          grandParentDeadCountry) !== "";
    }

    validationResult =
      validationResult &&
      (grandParentDead === "sim" || grandParentDead === "não");

    if (grandParentIsMarried) {
      validationResult =
        validationResult &&
        (grandParentMarriedWith &&
          grandParentMarriedWith &&
          grandParentMarriedCity &&
          grandParentMarriedCountry &&
          grandParentMarriedDate) !== "";
    }

    validationResult =
      validationResult &&
      (grandParentMarried === "sim" || grandParentMarried === "não");

    validationResult =
      validationResult &&
      (grandParentFullName &&
        grandParentBirthday &&
        grandParentBornCity &&
        grandParentCountry) !== "";

    setThirdFragmentValid(validationResult);
  }, [formData, deadGrandParent, grandParentIsMarried]);

  const validateFourthFragment = useCallback(() => {
    let validationResult = true;
    const {
      greatGrandParentFullName,
      greatGrandParentBirthday,
      greatGrandParentBornCity,
      greatGrandParentCountry,
      greatGrandParentMarried,
      greatGrandParentMarriedWith,
      greatGrandParentMarriedCity,
      greatGrandParentMarriedCountry,
      greatGrandParentMarriedDate,
      greatGrandParentDead,
      greatGrandParentDeadDate,
      greatGrandParentDeadCity,
      greatGrandParentDeadCountry,
    } = formData;

    if (deadGreatGrandParent) {
      validationResult =
        validationResult &&
        (greatGrandParentDeadDate &&
          greatGrandParentDeadCity &&
          greatGrandParentDeadCountry) !== "";
    }

    validationResult =
      validationResult &&
      (greatGrandParentDead === "sim" || greatGrandParentDead === "não");

    if (greatGrandParentIsMarried) {
      validationResult =
        validationResult &&
        (greatGrandParentMarried &&
          greatGrandParentMarriedWith &&
          greatGrandParentMarriedCity &&
          greatGrandParentMarriedCountry &&
          greatGrandParentMarriedDate) !== "";
    }

    validationResult =
      validationResult &&
      (greatGrandParentMarried === "sim" || greatGrandParentMarried === "não");

    validationResult =
      validationResult &&
      (greatGrandParentFullName &&
        greatGrandParentBirthday &&
        greatGrandParentBornCity &&
        greatGrandParentCountry) !== "";

    setFourthFragmentValid(validationResult);
  }, [formData, deadGreatGrandParent, greatGrandParentIsMarried]);

  const validateFifthFragment = useCallback(() => {
    let validationResult = true;
    const {
      greatGreatGrandParentFullName,
      greatGreatGrandParentBirthday,
      greatGreatGrandParentBornCity,
      greatGreatGrandParentCountry,
      greatGreatGrandParentMarried,
      greatGreatGrandParentMarriedWith,
      greatGreatGrandParentMarriedCity,
      greatGreatGrandParentMarriedCountry,
      greatGreatGrandParentMarriedDate,
      greatGreatGrandParentDead,
      greatGreatGrandParentDeadDate,
      greatGreatGrandParentDeadCity,
      greatGreatGrandParentDeadCountry,
    } = formData;

    if (deadGreatGreatGrandParent) {
      validationResult =
        validationResult &&
        (greatGreatGrandParentDeadDate &&
          greatGreatGrandParentDeadCity &&
          greatGreatGrandParentDeadCountry) !== "";
    }

    validationResult =
      validationResult &&
      (greatGreatGrandParentDead === "sim" ||
        greatGreatGrandParentDead === "não");

    if (greatGreatGrandParentIsMarried) {
      validationResult =
        validationResult &&
        (greatGreatGrandParentMarried &&
          greatGreatGrandParentMarriedWith &&
          greatGreatGrandParentMarriedCity &&
          greatGreatGrandParentMarriedCountry &&
          greatGreatGrandParentMarriedDate) !== "";
    }

    validationResult =
      validationResult &&
      (greatGreatGrandParentMarried === "sim" ||
        greatGreatGrandParentMarried === "não");

    validationResult =
      validationResult &&
      (greatGreatGrandParentFullName &&
        greatGreatGrandParentBirthday &&
        greatGreatGrandParentBornCity &&
        greatGreatGrandParentCountry) !== "";

    setFifthFragmentValid(validationResult);
  }, [formData, deadGreatGreatGrandParent, greatGreatGrandParentIsMarried]);

  const revalidateFormFragments = useCallback(() => {
    validateFirstFragment();
    validateSecondFragment();
    validateThirdFragment();
    validateFourthFragment();
    validateFifthFragment();
  }, [
    validateFirstFragment,
    validateFourthFragment,
    validateSecondFragment,
    validateThirdFragment,
    validateFifthFragment,
  ]);

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
      revalidateFormFragments();
    },
    [formData, revalidateFormFragments]
  );

  const handleConditionalFormBlock = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      updateStateFunction: Dispatch<SetStateAction<boolean>>
    ) => {
      const { value } = event.target;
      handleInputChange(event);
      updateStateFunction(value === "sim");
    },
    [handleInputChange]
  );

  const handleStep = useCallback(
    (step: number) => {
      setErrorSteps(
        errorSteps.splice(
          errorSteps.findIndex((errorStep) => errorStep === step, 1)
        )
      );
      setCurrentStep(step);

      !visitedSteps.includes(step) && setVisitedSteps([...visitedSteps, step]);
    },
    [errorSteps, visitedSteps]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      await api
        .post("/mail.php", {
          ...formData,
        })
        .then((response) => {
          handleStep(6);
        })
        .catch((error) => {
          const { message, step } = error.response.data as IResponse;
          console.dir(error);
          alert(message);

          setVisitedSteps(
            visitedSteps.filter(
              (visitedStep) => !errorSteps.includes(visitedStep)
            )
          );
          setErrorSteps([step]);
          handleStep(Number(step));
        });
    },
    [errorSteps, formData, handleStep, visitedSteps]
  );

  useEffect(() => {
    revalidateFormFragments();
  }, [formData, revalidateFormFragments]);

  return (
    <Wrapper>
      <div className="contact-form">
        <div className="title-wrapper">
          <h4>Informações da sua árvore genealógica </h4>
          <p>Suas informações serão analisadas pela equipe</p>
        </div>
      </div>

      <ContentCard>
        {currentStep < 6 && (
          <Steps>
            <Step
              type="button"
              className={`${visitedSteps.includes(1) && "visited"} ${
                currentStep === 1 && "current"
              }`}
            >
              1 Pessoal
            </Step>
            <Step
              type="button"
              className={`${visitedSteps.includes(2) && "visited"} ${
                currentStep === 2 && "current"
              }`}
            >
              2 Pai/Mãe
            </Step>
            <Step
              type="button"
              className={`${visitedSteps.includes(3) && "visited"} ${
                currentStep === 3 && "current"
              }`}
            >
              3 Avô(ó)
            </Step>
            <Step
              type="button"
              className={`${visitedSteps.includes(4) && "visited"} ${
                currentStep === 4 && "current"
              }`}
            >
              4 Bisavô(ó)
            </Step>
            <Step
              type="button"
              className={`${visitedSteps.includes(5) && "visited"} ${
                currentStep === 5 && "current"
              }`}
            >
              5 Trisavô(ó)
            </Step>
          </Steps>
        )}
        {currentStep < 6 && (
          <form ref={formReference} onSubmit={(e) => handleSubmit(e)}>
            <FormFragment visible={currentStep === 1}>
              <legend>
                <h5>Dados</h5>
              </legend>

              <Input
                required
                name="fullName"
                label="Nome completo:"
                onChange={handleInputChange}
              />
              <Input
                required
                name="email"
                label="E-mail:"
                type="email"
                onChange={handleInputChange}
              />
              <Input
                required
                name="phone"
                label="Telefone:"
                placeholder="(DDD) 99999-9999"
                type="tel"
                onChange={handleInputChange}
              />
              <Input
                required
                name="birthday"
                label="Data de nascimento:"
                type="date"
                onChange={handleInputChange}
              />
              <Input
                required
                name="address"
                label="Endereço:"
                placeholder="Rua, número/ complemento"
                onChange={handleInputChange}
              />
              <div className="field-group">
                <Input
                  required
                  name="city"
                  label="Cidade:"
                  onChange={handleInputChange}
                />
                <Select
                  required
                  name="country"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>
              <Input
                required
                name="labor"
                label="Profissão:"
                onChange={handleInputChange}
              />
              <FieldGroupRadio>
                <span>Casado?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="married"
                  id="marriedYes"
                  onChange={(e) => handleConditionalFormBlock(e, setIsMerried)}
                />
                <label htmlFor="marriedYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="married"
                  id="marriedNo"
                  onChange={(e) => handleConditionalFormBlock(e, setIsMerried)}
                />
                <label htmlFor="marriedNo">Não</label>
              </FieldGroupRadio>
              <Input
                required={isMarried}
                off={!isMarried}
                disabled={!isMarried}
                name="marriedWith"
                label="Casado com:"
                onChange={handleInputChange}
              />
              <div className="field-group">
                <Input
                  required={isMarried}
                  off={!isMarried}
                  disabled={!isMarried}
                  name="marriedCity"
                  label="Cidade do casamento:"
                  onChange={handleInputChange}
                />
                <Select
                  required={isMarried}
                  off={!isMarried}
                  disabled={!isMarried}
                  name="marriedCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <Input
                required={isMarried}
                off={!isMarried}
                disabled={!isMarried}
                name="marriedDate"
                label="Data do casamento:"
                type="date"
                onChange={handleInputChange}
              />

              <FieldGroupRadio>
                <span>Filhos?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="sons"
                  id="doHaveSons"
                  onChange={(e) => handleConditionalFormBlock(e, setHaveSons)}
                />
                <label htmlFor="doHaveSons">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="sons"
                  id="dontHaveSons"
                  onChange={(e) => handleConditionalFormBlock(e, setHaveSons)}
                />
                <label htmlFor="dontHaveSons">Não</label>
              </FieldGroupRadio>
              <Input
                required={haveSons}
                off={!haveSons}
                disabled={!haveSons}
                name="sonsAges"
                label="Idades:"
                placeholder="14,18,24"
                type="text"
                onChange={handleInputChange}
              />

              <PrivacyCheck className="privacy-check">
                <input
                  type="radio"
                  value="sim"
                  name="privacy"
                  id="privacy"
                  onChange={handleInputChange}
                />
                <label htmlFor="privacy">
                  Estou de acordo em fornecer minhas informações para análise
                  apenas relacionada com o processo de Cidadania Italiana.
                  Aceito que o escritório Fabrízia Bazana entre em contato
                  comigo.
                  <br />
                  <a target="_blank" href="/privacidade">
                    Veja nossa política de privacidade.
                  </a>
                </label>
              </PrivacyCheck>
              <div className="buttons">
                <Button
                  disabled={!firstFragmentValid}
                  onClick={() => handleStep(2)}
                >
                  Próximo
                </Button>
              </div>
            </FormFragment>

            <FormFragment visible={currentStep === 2}>
              <legend>
                <h5>Pai/Mãe (origem italiana)</h5>
              </legend>

              <Input
                required
                name="parentFullName"
                label="Nome completo:"
                onChange={handleInputChange}
              />

              <Input
                required
                name="parentBirthday"
                label="Data de nascimento:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required
                  name="parentBornCity"
                  label="Cidade de Nascimento:"
                  onChange={handleInputChange}
                />
                <Select
                  required
                  name="parentCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <FieldGroupRadio>
                <span>Casado?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="parentMarried"
                  id="parentMarriedYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setParentIsMerried)
                  }
                />
                <label htmlFor="parentMarriedYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="parentMarried"
                  id="parentMarriedNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setParentIsMerried)
                  }
                />
                <label htmlFor="parentMarriedNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={parentIsMarried}
                off={!parentIsMarried}
                disabled={!parentIsMarried}
                name="parentMarriedWith"
                label="Casado com:"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={parentIsMarried}
                  off={!parentIsMarried}
                  disabled={!parentIsMarried}
                  name="parentMarriedCity"
                  label="Cidade de casam.:"
                  onChange={handleInputChange}
                />
                <Select
                  required={parentIsMarried}
                  off={!parentIsMarried}
                  disabled={!parentIsMarried}
                  name="parentMarriedCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <Input
                required={parentIsMarried}
                off={!parentIsMarried}
                disabled={!parentIsMarried}
                name="parentMarriedDate"
                label="Data do casamento:"
                type="date"
                onChange={handleInputChange}
              />

              <FieldGroupRadio>
                <span>Falecido?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="parentDead"
                  id="parentDeadYes"
                  onChange={(e) => handleConditionalFormBlock(e, setDeadParent)}
                />
                <label htmlFor="parentDeadYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="parentDead"
                  id="parentDeadNo"
                  onChange={(e) => handleConditionalFormBlock(e, setDeadParent)}
                />
                <label htmlFor="parentDeadNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={deadParent}
                off={!deadParent}
                disabled={!deadParent}
                name="parentDeadDate"
                label="Data do óbito:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={deadParent}
                  off={!deadParent}
                  disabled={!deadParent}
                  name="parentDeadCity"
                  label="Cidade do óbito:"
                  onChange={handleInputChange}
                />
                <Select
                  required={deadParent}
                  off={!deadParent}
                  disabled={!deadParent}
                  name="parentDeadCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <div className="buttons">
                <Button className="secondary" onClick={() => handleStep(1)}>
                  Voltar
                </Button>
                <Button
                  disabled={!secondFragmentValid}
                  onClick={() => handleStep(3)}
                >
                  Próximo
                </Button>
              </div>
            </FormFragment>

            <FormFragment visible={currentStep === 3}>
              <legend>
                <h5>Avô (origem italiana)</h5>
              </legend>

              <Input
                required
                name="grandParentFullName"
                label="Nome completo:"
                onChange={handleInputChange}
              />

              <Input
                required
                name="grandParentBirthday"
                label="Data de nascimento:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required
                  name="grandParentBornCity"
                  label="Cidade de Nascimento:"
                  onChange={handleInputChange}
                />
                <Select
                  required
                  name="grandParentCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <FieldGroupRadio>
                <span>Casado?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="grandParentMarried"
                  id="grandParentMarriedYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setGrandParentIsMerried)
                  }
                />
                <label htmlFor="grandParentMarriedYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="grandParentMarried"
                  id="grandParentMarriedNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setGrandParentIsMerried)
                  }
                />
                <label htmlFor="grandParentMarriedNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={grandParentIsMarried}
                off={!grandParentIsMarried}
                disabled={!grandParentIsMarried}
                name="grandParentMarriedWith"
                label="Casado com:"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={grandParentIsMarried}
                  off={!grandParentIsMarried}
                  disabled={!grandParentIsMarried}
                  name="grandParentMarriedCity"
                  label="Cidade de casam.:"
                  onChange={handleInputChange}
                />
                <Select
                  required={grandParentIsMarried}
                  off={!grandParentIsMarried}
                  disabled={!grandParentIsMarried}
                  name="grandParentMarriedCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <Input
                required={grandParentIsMarried}
                off={!grandParentIsMarried}
                disabled={!grandParentIsMarried}
                name="grandParentMarriedDate"
                label="Data do casamento:"
                type="date"
                onChange={handleInputChange}
              />

              <FieldGroupRadio>
                <span>Falecido?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="grandParentDead"
                  id="grandParentDeadYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGrandParent)
                  }
                />
                <label htmlFor="grandParentDeadYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="grandParentDead"
                  id="grandParentDeadNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGrandParent)
                  }
                />
                <label htmlFor="grandParentDeadNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={deadGrandParent}
                off={!deadGrandParent}
                disabled={!deadGrandParent}
                name="grandParentDeadDate"
                label="Data do óbito::"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={deadGrandParent}
                  off={!deadGrandParent}
                  disabled={!deadGrandParent}
                  name="grandParentDeadCity"
                  label="Cidade do óbito:"
                  onChange={handleInputChange}
                />
                <Select
                  required={deadGrandParent}
                  off={!deadGrandParent}
                  disabled={!deadGrandParent}
                  name="grandParentDeadCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <div className="buttons">
                <Button className="secondary" onClick={() => handleStep(2)}>
                  Voltar
                </Button>
                <Button
                  disabled={!thirdFragmentValid}
                  onClick={() => handleStep(4)}
                >
                  Próximo
                </Button>
              </div>
            </FormFragment>

            <FormFragment visible={currentStep === 4}>
              <legend>
                <h5>Bisavô (origem italiana)</h5>
              </legend>

              <Input
                required
                name="greatGrandParentFullName"
                label="Nome completo:"
                onChange={handleInputChange}
              />

              <Input
                required
                name="greatGrandParentBirthday"
                label="Data de nascimento:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required
                  name="greatGrandParentBornCity"
                  label="Cidade de Nascimento:"
                  onChange={handleInputChange}
                />
                <Select
                  required
                  name="greatGrandParentCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <FieldGroupRadio>
                <span>Casado?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="greatGrandParentMarried"
                  id="greatGrandParentMarriedYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setGreatGrandParentIsMerried)
                  }
                />
                <label htmlFor="greatGrandParentMarriedYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="greatGrandParentMarried"
                  id="greatGrandParentMarriedNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setGreatGrandParentIsMerried)
                  }
                />
                <label htmlFor="greatGrandParentMarriedNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={greatGrandParentIsMarried}
                off={!greatGrandParentIsMarried}
                disabled={!greatGrandParentIsMarried}
                name="greatGrandParentMarriedWith"
                label="Casado com:"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={greatGrandParentIsMarried}
                  off={!greatGrandParentIsMarried}
                  disabled={!greatGrandParentIsMarried}
                  name="greatGrandParentMarriedCity"
                  label="Cidade de casam.:"
                  onChange={handleInputChange}
                />
                <Select
                  required={greatGrandParentIsMarried}
                  off={!greatGrandParentIsMarried}
                  disabled={!greatGrandParentIsMarried}
                  name="greatGrandParentMarriedCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <Input
                required={greatGrandParentIsMarried}
                off={!greatGrandParentIsMarried}
                disabled={!greatGrandParentIsMarried}
                name="greatGrandParentMarriedDate"
                label="Data do casamento:"
                type="date"
                onChange={handleInputChange}
              />

              <FieldGroupRadio>
                <span>Falecido?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="greatGrandParentDead"
                  id="greatGrandParentDeadYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGreatGrandParent)
                  }
                />
                <label htmlFor="greatGrandParentDeadYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="greatGrandParentDead"
                  id="greatGrandParentDeadNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGreatGrandParent)
                  }
                />
                <label htmlFor="greatGrandParentDeadNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={deadGreatGrandParent}
                off={!deadGreatGrandParent}
                disabled={!deadGreatGrandParent}
                name="greatGrandParentDeadDate"
                label="Data do óbito:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={deadGreatGrandParent}
                  off={!deadGreatGrandParent}
                  disabled={!deadGreatGrandParent}
                  name="greatGrandParentDeadCity"
                  label="Cidade do óbito:"
                  onChange={handleInputChange}
                />
                <Select
                  required={deadGreatGrandParent}
                  off={!deadGreatGrandParent}
                  disabled={!deadGreatGrandParent}
                  name="greatGrandParentDeadCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <div className="buttons">
                <Button className="secondary" onClick={() => handleStep(3)}>
                  Voltar
                </Button>
                <Button
                  disabled={!fourthFragmentValid}
                  onClick={() => handleStep(5)}
                >
                  Próximo
                </Button>
              </div>
            </FormFragment>

            <FormFragment visible={currentStep === 5}>
              <legend>
                <h5>Trisavô (origem italiana)</h5>
              </legend>

              <Input
                required
                name="greatGreatGrandParentFullName"
                label="Nome completo:"
                onChange={handleInputChange}
              />

              <Input
                required
                name="greatGreatGrandParentBirthday"
                label="Data de nascimento:"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required
                  name="greatGreatGrandParentBornCity"
                  label="Cidade de Nascimento:"
                  onChange={handleInputChange}
                />
                <Select
                  required
                  name="greatGreatGrandParentCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <FieldGroupRadio>
                <span>Casado?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="greatGreatGrandParentMarried"
                  id="greatGreatGrandParentMarriedYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(
                      e,
                      setGreatGreatGrandParentIsMerried
                    )
                  }
                />
                <label htmlFor="greatGreatGrandParentMarriedYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="greatGreatGrandParentMarried"
                  id="greatGreatGrandParentMarriedNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(
                      e,
                      setGreatGreatGrandParentIsMerried
                    )
                  }
                />
                <label htmlFor="greatGreatGrandParentMarriedNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={greatGreatGrandParentIsMarried}
                off={!greatGreatGrandParentIsMarried}
                disabled={!greatGreatGrandParentIsMarried}
                name="greatGreatGrandParentMarriedWith"
                label="Casado com:"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={greatGreatGrandParentIsMarried}
                  off={!greatGreatGrandParentIsMarried}
                  disabled={!greatGreatGrandParentIsMarried}
                  name="greatGreatGrandParentMarriedCity"
                  label="Cidade de casam.:"
                  onChange={handleInputChange}
                />
                <Select
                  required={greatGreatGrandParentIsMarried}
                  off={!greatGreatGrandParentIsMarried}
                  disabled={!greatGreatGrandParentIsMarried}
                  name="greatGreatGrandParentMarriedCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <Input
                required={greatGreatGrandParentIsMarried}
                off={!greatGreatGrandParentIsMarried}
                disabled={!greatGreatGrandParentIsMarried}
                name="greatGreatGrandParentMarriedDate"
                label="Data do casamento:"
                type="date"
                onChange={handleInputChange}
              />

              <FieldGroupRadio>
                <span>Falecido?</span>
                <input
                  required
                  type="radio"
                  value="sim"
                  name="greatGreatGrandParentDead"
                  id="greatGreatGrandParentDeadYes"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGreatGreatGrandParent)
                  }
                />
                <label htmlFor="greatGreatGrandParentDeadYes">Sim</label>

                <input
                  required
                  type="radio"
                  value="não"
                  name="greatGreatGrandParentDead"
                  id="greatGreatGrandParentDeadNo"
                  onChange={(e) =>
                    handleConditionalFormBlock(e, setDeadGreatGreatGrandParent)
                  }
                />
                <label htmlFor="greatGreatGrandParentDeadNo">Não</label>
              </FieldGroupRadio>

              <Input
                required={deadGreatGreatGrandParent}
                off={!deadGreatGreatGrandParent}
                disabled={!deadGreatGreatGrandParent}
                name="greatGreatGrandParentDeadDate"
                label="Data do óbito::"
                type="date"
                onChange={handleInputChange}
              />

              <div className="field-group">
                <Input
                  required={deadGreatGreatGrandParent}
                  off={!deadGreatGreatGrandParent}
                  disabled={!deadGreatGreatGrandParent}
                  name="greatGreatGrandParentDeadCity"
                  label="Cidade do óbito:"
                  onChange={handleInputChange}
                />
                <Select
                  required={deadGreatGreatGrandParent}
                  off={!deadGreatGreatGrandParent}
                  disabled={!deadGreatGreatGrandParent}
                  name="greatGreatGrandParentDeadCountry"
                  label="País:"
                  handleSelect={handleInputChange}
                />
              </div>

              <div className="buttons">
                <Button className="secondary" onClick={() => handleStep(4)}>
                  Voltar
                </Button>
                <Button
                  disabled={
                    !firstFragmentValid ||
                    !secondFragmentValid ||
                    !thirdFragmentValid ||
                    !fourthFragmentValid ||
                    !fifthFragmentValid
                  }
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Enviar minhas informações
                </Button>
              </div>
            </FormFragment>
          </form>
        )}

        <FormFragment visible={currentStep === 6}>
          <SubmittedFeedback>
            <h5>Informações enviadas com sucesso!</h5>
            <ul>
              <li>
                <img src={checkIcon} alt="icone de confirmado com sucesso." />
                <p>Informações pessoais;</p>
              </li>
              <li>
                <img src={checkIcon} alt="icone de confirmado com sucesso." />
                <p>Informações do pai ou mãe;</p>
              </li>
              <li>
                <img src={checkIcon} alt="icone de confirmado com sucesso." />
                <p>Informações do avô;</p>
              </li>
              <li>
                <img src={checkIcon} alt="icone de confirmado com sucesso." />
                <p>Informações do bisavô;</p>
              </li>
              <li>
                <img src={checkIcon} alt="icone de confirmado com sucesso." />
                <p>Informações do trisavô.</p>
              </li>
            </ul>
            <p>Entraremos em contato em breve.</p>
          </SubmittedFeedback>
        </FormFragment>
      </ContentCard>
    </Wrapper>
  );
};

export default ContactForm;
