import styled, { css } from "styled-components";

interface IFieldGroupRadioProps {
  off?: boolean;
}
interface ICardContentProps {
  isOpen: boolean;
}
interface IFormFragmentProps {
  visible: boolean;
}
export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 60.6rem;
  max-width: 100%;

  .contact-form {
    display: flex;
    align-items: center;
    padding: 2.4rem 3.3rem 1.6rem 3.3rem;
    outline: none;
    border: none;
    background-color: var(--green);
    border-radius: 1.1rem;

    .title-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      line-height: 2.8rem;
      width: 100%;

      p {
        color: var(--white);
        font-size: 1.8rem;
        text-align: left;
      }
    }
    @media (max-width: 600px) {
      .title-wrapper h4 {
        text-align: center;
      }
      .title-wrapper p {
        display: none;
      }
    }
    h4 {
      color: var(--white);
      font-size: 1.8rem;
      font-weight: bold;
      text-align: left;
    }
    img {
      transition: transform 1.4s;
    }
    img.opened {
      transform: rotate(180deg);
    }
  }
`;

export const FieldGroupRadio = styled.div<IFieldGroupRadioProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 1.3rem 2.1rem 1rem 2.1rem;
  height: 5rem;
  transition: opacity 0.2s linear;

  ${({ off }) =>
    off &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  input[type="radio"] {
    display: none;
    + label {
      margin-left: 5.7rem;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        background: var(--grey);
        left: -3.2rem;
        top: -0.1rem;
        transition: clip-path 0.1s linear;
        clip-path: polygon(100% 0, 100% 0%, 100% 100%, 0 100%, 0 0%, 0 0);
      }
    }
    &:checked {
      + label {
        &:before {
          background: var(--green-lighter);
          clip-path: polygon(
            37% 63%,
            84% 14%,
            99% 21%,
            38% 87%,
            4% 51%,
            15% 40%
          );
          top: -0.1rem;
        }
      }
    }
  }

  &.invert {
    background-color: var(--grey);
    input[type="radio"] {
      + label:before {
        background: var(--white);
      }
      &:checked {
        + label:before {
          background: var(--green-lighter);
        }
      }
    }
  }
`;

export const PrivacyCheck = styled(FieldGroupRadio)`
  &.privacy-check {
    display: flex;
    height: auto;
    padding-right: 0rem;
    padding-left: 0rem;
    margin-left: 3.3rem;
  }

  input[type="radio"] + label {
    margin: 0rem;
    font-size: 1.6rem;
    a {
      color: var(--brown-darker);
      font-size: 1.6rem;
      font-weight: bold;
      text-decoration: none;
    }
  }
`;

export const ContentCard = styled.div`
  visibility: visible;
  height: auto;
  width: 100%;
  display: grid;
  justify-content: center;
  animation: showInfos 1.4s ease;
  background-color: var(--white);
  border-radius: 0rem 0rem 1.1rem 1.1rem;
  color: var(--green-darker);
  font-size: 1.6rem;
  max-width: 96.36%;
  margin-left: auto;
  margin-right: auto;
  padding: 3.5rem 2.6rem 4rem 2.6rem;
  width: 100%;
  height: auto;
  white-space: pre-line;
`;
export const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3.5rem;
`;
export const Step = styled.button`
  width: 9rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0.4rem 0.2rem 0.2rem 0.7rem;
  border-radius: 0;
  margin-top: 1.6rem;
  & + button {
    margin-left: 2rem;
  }

  &.visited {
    background-color: var(--green-lighter);
    color: var(--white);
  }
  &.current {
    background-color: var(--brown-darker);
    color: var(--white);
  }

  color: var(--grey-darker);
`;
export const FormFragment = styled.fieldset<IFormFragmentProps>`
  visibility: hidden;
  display: none;
  border: none;
  width: 100%;

  legend h5 {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-bottom: 1.4rem;
  }
  .field-group {
    display: flex;
    flex-flow: column;
    ~ div {
      margin-top: 1rem;
    }
  }

  div.buttons {
    margin-top: 3rem;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    place-content: flex-end;
    grid-gap: 2.6rem;

    button.secondary {
      margin-right: auto;
    }

    @media (max-width: 600px) {
      button {
        width: 100%;
      }
    }
  }

  @media (min-width: 475px) {
    .field-group {
      flex-flow: row;
      grid-gap: 1rem;
      div:first-child {
        flex-grow: 1;
        input {
          width: 100%;
        }
      }
      div + div {
        margin-top: 0px;
        max-width: 45%;
        width: 18.5rem;
        input {
          width: 100%;
        }
      }
    }
  }

  ${(props) =>
    props.visible &&
    css`
      visibility: visible;
      display: flex;
      flex-flow: column;
      animation: showInfos 1.4s ease;
    `};
`;

export const SubmittedFeedback = styled.div`
  display: flex;
  flex-flow: column;
  margin: -1rem 0rem 2.9rem 2.1rem;

  h5 {
    font-size: 2.2rem;
    min-height: 3rem;
    margin-bottom: 2.9rem;
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: column;
    margin-bottom: 4.17rem;
    li {
      display: flex;
      grid-gap: 1.58rem;
      img {
        opacity: 0;
        animation: showInfos 0.4s linear forwards;
        animation-delay: 0.4s;
      }

      &:nth-child(2) {
        img {
          animation-delay: 0.6s;
        }
      }
      &:nth-child(3) {
        img {
          animation-delay: 0.8s;
        }
      }
      &:nth-child(4) {
        img {
          animation-delay: 1s;
        }
      }
      &:nth-child(5) {
        img {
          animation-delay: 1.2s;
        }
      }

      + li {
        margin-top: 2.57rem;
      }
    }
  }
`;
