import React, { useCallback, useEffect, useState } from "react";
import { IPrivacyText } from "../../data/database";

import { Wrapper, ContentCard, SVGtoggleIcon } from "./styles";

interface IToggleCardProps extends IPrivacyText {
  citizenshipMethod?: string;
  open: boolean;
  handleChangeOpenCard?: React.Dispatch<React.SetStateAction<number>>;
  cardCustomIndex?: number;
}

const ToggleCard: React.FC<IToggleCardProps> = ({
  cardText,
  cardTitle,
  handleChangeOpenCard,
  cardCustomIndex,
  citizenshipMethod,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleAutoCloseCard = useCallback(() => {
    if (!handleChangeOpenCard || !cardCustomIndex) {
      return;
    }
    handleChangeOpenCard(cardCustomIndex);
  }, [cardCustomIndex, handleChangeOpenCard]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    handleAutoCloseCard();
  }, [handleAutoCloseCard, isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Wrapper>
      <button type="button" onClick={handleToggle}>
        <div className="title-wrapper">
          <h4>{cardTitle}</h4>
          {citizenshipMethod && <p>{citizenshipMethod}</p>}
        </div>
        <SVGtoggleIcon className={isOpen ? "open" : ""} />
      </button>

      <ContentCard isOpen={isOpen}>
        <p dangerouslySetInnerHTML={{ __html: cardText }} />
      </ContentCard>
    </Wrapper>
  );
};

export default ToggleCard;
