import React from "react";
import { CgClose } from "react-icons/cg";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { LinkInIcon } from "../Header/styles";
import Logo from "../Logo";
import { Container, GoToForm } from "./styled";

interface IMenuMobileProps {
  handleCloseMenuMobile: () => void;
}

export const MenuMobile: React.FC<IMenuMobileProps> = ({
  handleCloseMenuMobile,
}) => {
  return (
    <Container>
      <div className="mobile-menu-header">
        <Logo />
        <button type="button" onClick={handleCloseMenuMobile}>
          <CgClose />
        </button>
      </div>

      <ul className="mobile-menu-pages">
        <li>
          <a href="/Sobre">Sobre</a>
        </li>
        <li>
          <a href="/Cidadania">Cidadania Italiana</a>
        </li>
        <li>
          <a href="/Acervo">Nosso acervo</a>
        </li>
        <li>
          <a href="/Contato">Contato</a>
        </li>
      </ul>

      <GoToForm href="/Contato"> Preencher árvore genealógica</GoToForm>

      <div className="mobile-menu-footer">
        <div>
          <LinkInIcon
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/fabriziabazana/"
          >
            <FaLinkedinIn />
          </LinkInIcon>
          <LinkInIcon
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/5551993552839"
          >
            <FaWhatsapp />
          </LinkInIcon>
        </div>
        <ul>
          <li>
            <a href="/Privacidade">Política de Privacidade</a>
          </li>
          <li>
            <a href="/Privacidade">Todos os direitos reservados</a>
          </li>
          <li>
            <a
              id="PriscilaFelippetoLink"
              href="https://www.priscilafelippetto.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Desenhado por Priscila Felippetto
            </a>
          </li>
        </ul>
      </div>
    </Container>
  );
};
