import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HeroImageSVG from "../../assets/images/Torre-Pisa-topo-site.svg";
import HeroWaveSVG from "../../assets/images/hero-wave.svg";
import About from "../../components/About";
import Achievements from "../../components/Achievements";
import Button from "../../components/Button";
import Citizenship from "../../components/Citizenship";
import Contact from "../../components/Contact";
import CurvedShape from "../../components/Curved";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Review from "../../components/Reviews";
import { IReviewText, reviewsTexts } from "../../data/database";
import { HeroImage, HeroSection, HeroWave, Main, TextsWrapper } from "./styles";

const Home: React.FC = () => {
  const history = useHistory();
  const [reviews] = useState<IReviewText[]>(reviewsTexts);
  const [selectedReviews, setSelectedReviews] = useState<IReviewText[]>(
    [] as IReviewText[]
  );

  useEffect(() => {
    function shuffle() {
      reviews.sort(() => Math.random() - 0.5);
    }

    shuffle();

    const [firstReview, secondReview, thirdReview] = reviews;

    setSelectedReviews([firstReview, secondReview, thirdReview]);
  }, [reviews]);

  return (
    <div>
      <Header />

      <Main>
        <div className="wrapper-tower">
          <HeroImage>
            <img id="desktop-img" src={HeroImageSVG} alt="Torre de Pisa" />
          </HeroImage>
        </div>
        <HeroSection className="sub-container">
          <TextsWrapper>
            <h1>
              Cidadania
              <br /> Italiana
            </h1>
            <p>
              Assessoria para reconhecimento da Cidadania Italiana de forma
              lícita e regular.
            </p>
            <Button
              onClick={async () => {
                await history.push("");
                history.push("/Contato");
              }}
            >
              Entrar em contato
            </Button>
          </TextsWrapper>

          <HeroWave>
            <img src={HeroWaveSVG} alt="Objeto com forma de onda" />
          </HeroWave>
          <HeroImage>
            <img src={HeroImageSVG} alt="Torre de Pisa" />
          </HeroImage>
        </HeroSection>
        <div className="curve-darker">
          <CurvedShape customColor="--green" />
        </div>

        <About />
        <Citizenship>
          {selectedReviews[1] && <Review {...selectedReviews[1]} />}
        </Citizenship>
        <Achievements>
          {selectedReviews[0] && <Review {...selectedReviews[0]} />}
        </Achievements>

        <Contact>
          {selectedReviews[2] && <Review {...selectedReviews[2]} />}
        </Contact>
      </Main>
      <Footer />
    </div>
  );
};

export default Home;
