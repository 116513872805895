import React, { useCallback } from "react";
import { FaWhatsapp } from "react-icons/fa";
import ContactForm from "../ContactForm";
import { Container, GoToTop, SVGArrowUp } from "./styles";

const Contact: React.FC = ({ children }) => {
  const handleScroll = useCallback((destinationId: string) => {
    const destinationElement = document.querySelector(`#${destinationId}`);

    destinationElement?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <Container sectionColor="var(--grey)">
      {children}
      <h2 className="container">Contato</h2>
      <div className="sub-container">
        <div className="first-column">
          <p>
            Para começarmos a conversar sobre seu processo de Cidadania
            Italiana, preencha a árvore genealógica. Visualizando suas
            informações, poderemos dar um retorno mais efetivo sobre as
            possibilidades existentes para você adquirir a cidadania. E não se
            preocupe, todas suas informações serão tratadas com
            confidencialidade. Nós as usaremos apenas para conversarmos com você
            sobre o processo de Cidadania Italiana.
            <br />
            <br />
            Nossos clientes estão pelo mundo todo, mas nossos escritórios ficam
            nos seguintes endereços:
          </p>
          <div className="address-wrapper">
            <h5>São Paulo</h5>
            <p>Av. Paulista, 1765/ conj. 72, Bela Vista</p>

            <a href="tel:+551145900052">Telefone: +55 (11) 4590-0052</a>
          </div>
          <div className="address-wrapper">
            <h5>Porto Alegre</h5>
            <p>Av. Independência 925, sala 1501, Independência</p>

            <a href="tel:+551145900052">Telefone: +55 (51) 3028-7072</a>
          </div>

          <div className="contact-whatsapp">
            <b>Nos chame também pelo </b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/5551993552839"
            >
              <span>WhatsApp</span>
              <FaWhatsapp
                size="2.928rem"
                color="var(--green-darker)"
              ></FaWhatsapp>
            </a>
          </div>
        </div>
        <div className="second-column" id="Contato">
          <ContactForm />
        </div>
      </div>
      <GoToTop className="container">
        <SVGArrowUp
          color="var(--green-lighter)"
          onClick={() => handleScroll("header")}
        />
      </GoToTop>
    </Container>
  );
};
export default Contact;
